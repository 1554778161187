import Modal from 'react-modal'
import {ModalStyling} from '../../../../../voter/components/registration_follow_up/ModalStyling'
import {ModalHeaderWithLabel} from '../../../../../voter/components/layout/ModalHeaderWithLabel'
import {useEffect, useState} from 'react'
import './ViewIdModal.scss'
import {toAbsoluteUrl} from '../../../../../../helperfunctions/AssetHelpers'
import {CustomBtn} from '../../../../../reusable/custom_btn/CustomBtn'

export const ViewIdModal = ({modalOpen, onClose, identification}) => {
  const [modalView, setModalView] = useState('view')

  const viewPhotoID = (
    <div className='viewIdModal__viewOuter'>
      <ModalHeaderWithLabel label={'Photo ID'} />
      <div className='viewIdModal__view'>
        {identification.map((photo) => (
          <img
            className='viewIdModal__view__photoId'
            alt='id'
            src={photo}
            style={{marginBottom: 10}}
          />
        ))}
      </div>
      <div className='ModalFooter'>
        <CustomBtn view='ActionBtn' text={'Close'} height={50} onClick={onClose} />
      </div>
    </div>
  )

  const renderView = () => {
    if (modalView == 'view') {
      return viewPhotoID
    }
  }

  useEffect(() => {
    console.log('identification', identification)
  }, [modalOpen])

  return (
    <Modal
      ariaHideApp={false}
      isOpen={modalOpen}
      onAfterOpen={() => {}}
      onRequestClose={onClose}
      style={ModalStyling}
    >
      <div className='ModalWrapper' style={{flexGrow: 1}}>
        {/* BODY CONTENT */}
        {renderView()}
      </div>
    </Modal>
  )
}
