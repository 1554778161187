import TextField from '../../../../modules/auth/reusable/TextField'
import SelectField from '../../../../modules/auth/reusable/SelectField'
import './AddressInfo.scss'
import '../../pages/VoterRegister.scss'
import {useVoterStyles} from '../../../../contexts/VoterStylesContext'

import {useEffect, useState} from 'react'
// import GooglePlacesAutoCompleteInput from '../../../../modules/reusable/GoogleAutocomplete'
import {mapProvinceCodeToName} from '../../../reusable/province_mapper/ProvinceMapper'
import {useTranslation} from '../../../../hooks/useTranslation'
import PlacesAutocomplete from '../../../../modules/reusable/GooglePlacesAutocomplete'

const provinces = [
  {value: 0, label: 'Province'},
  {value: 'Alberta', label: 'Alberta'},
  {value: 'British Columbia', label: 'British Columbia'},
  {value: 'Manitoba', label: 'Manitoba'},
  {value: 'New Brunswick', label: 'New Brunswick'},
  {value: 'Newfoundland and Labrador', label: 'Newfoundland and Labrador'},
  {value: 'Northwest Territories', label: 'Northwest Territories'},
  {value: 'Nova Scotia', label: 'Nova Scotia'},
  {value: 'Nunavut', label: 'Nunavut'},
  {value: 'Ontario', label: 'Ontario'},
  {value: 'Prince Edward Island', label: 'Prince Edward Island'},
  {value: 'Quebec', label: 'Quebec'},
  {value: 'Saskatchewan', label: 'Saskatchewan'},
  {value: 'Yukon', label: 'Yukon'},
]

export function AddressInfo({formik, view, customStyling = {}}) {
  const {translate} = useTranslation()

  const VoterRegister = (
    <>
      <div className='voterRegister__addressWrap'>
        <div className='voterRegister__addressInputWrap'>
          <PlacesAutocomplete
            view='voter'
            formik={formik}
            type='text'
            field='streetAddress'
            placeholder={translate('Street Address')}
            label={translate('Street Address')}
            isRequired={true}
            types={['address']}
            hideErrorLabel
          />
        </div>
        <div>
          <TextField
            formik={formik}
            type='text'
            field='unitNumber'
            placeholder={translate('Unit Number')}
            label={translate('Unit #/P.O.Box')}
            view='voter'
          />
        </div>
      </div>

      <div className='voterRegister__locationWrap'>
        <div className='voterRegister__locationInputsWrap'>
          <div className='voterRegister__cityInputWrap'>
            <TextField
              formik={formik}
              type='text'
              field='city'
              placeholder={translate('City')}
              label={translate('City')}
              isRequired={true}
              view='voter'
            />
          </div>

          <div className='voterRegister__provinceDropdownWrap'>
            <TextField
              formik={formik}
              type='text'
              field='province'
              placeholder={translate('Province')}
              label={translate('Province')}
              isRequired={true}
              view='voter'
            />
          </div>

          <div className='voterRegister__postalInputWrap'>
            <TextField
              formik={formik}
              type='text'
              field='postalCode'
              placeholder={translate('Postal Code')}
              label={translate('Postal Code')}
              isRequired={true}
              view='voter'
            />
          </div>
        </div>

        {formik.errors['city'] && (
          <div className='voterRegister__singleInputErr'>
            <div className='TextField__voterErrWrap'>
              <span className='TextField__voterErrTxt' role='alert'>
                {translate(formik.errors['city'])}
              </span>
            </div>
          </div>
        )}
        {formik.errors['province'] && (
          <div className='voterRegister__singleInputErr'>
            <div className='TextField__voterErrWrap'>
              <span className='TextField__voterErrTxt' role='alert'>
                {translate(formik.errors['province'])}
              </span>
            </div>
          </div>
        )}
        {formik.errors['postalCode'] && (
          <div className='voterRegister__singleInputErr'>
            <div className='TextField__voterErrWrap'>
              <span className='TextField__voterErrTxt' role='alert'>
                {translate(formik.errors['postalCode'])}
              </span>
            </div>
          </div>
        )}
      </div>
    </>
  )
  const AdminEdit = (
    <>
      <div className='voterRegister__addressWrap'>
        <div className='voterRegister__addressInputWrap'>
          <PlacesAutocomplete
            view='voter'
            formik={formik}
            type='text'
            field='streetAddress'
            placeholder={translate('Street Address')}
            label={translate('Street Address')}
            isRequired={true}
            types={['address']}
          />
        </div>
        <div>
          <TextField
            formik={formik}
            type='text'
            field='unitNumber'
            placeholder={translate('Unit Number')}
            label={translate('Unit #/P.O.Box')}
            view='voter'
          />
        </div>
      </div>

      <div className='voterRegister__locationWrap'>
        <div className='voterRegister__locationInputsWrap'>
          <div className='voterRegister__cityInputWrap'>
            <TextField
              formik={formik}
              type='text'
              field='city'
              placeholder={translate('City')}
              label={translate('City')}
              isRequired={true}
              view='voter'
            />
          </div>

          <div className='voterRegister__provinceDropdownWrap'>
            <SelectField
              formik={formik}
              type='text'
              field='province'
              placeholder={translate('Province')}
              label={translate('Province')}
              isRequired={true}
              options={provinces}
              view='voter'
            />
          </div>

          <div className='voterRegister__postalInputWrap'>
            <TextField
              formik={formik}
              type='text'
              field='postalCode'
              placeholder={translate('Postal Code')}
              label={translate('Postal Code')}
              isRequired={true}
              view='voter'
            />
          </div>
        </div>

        {formik.errors['city'] && (
          <div className='voterRegister__singleInputErr'>
            <div className='TextField__voterErrWrap'>
              <span className='TextField__voterErrTxt' role='alert'>
                {translate(formik.errors['city'])}
              </span>
            </div>
          </div>
        )}
        {formik.errors['province'] && (
          <div className='voterRegister__singleInputErr'>
            <div className='TextField__voterErrWrap'>
              <span className='TextField__voterErrTxt' role='alert'>
                {translate(formik.errors['province'])}
              </span>
            </div>
          </div>
        )}
        {formik.errors['postalCode'] && (
          <div className='voterRegister__singleInputErr'>
            <div className='TextField__voterErrWrap'>
              <span className='TextField__voterErrTxt' role='alert'>
                {translate(formik.errors['postalCode'])}
              </span>
            </div>
          </div>
        )}
      </div>
    </>
  )

  const renderView = () => {
    if (view == 'VoterRegister') {
      return VoterRegister
    }
    if (view == 'AdminEdit') {
      return AdminEdit
    }
  }

  return (
    <div className='voterRegister__addressRow' style={customStyling}>
      <div className='voterRegister__divider'>
        <span className={'voterRegister__dividerLabel'}>
          {translate('Qualifying Residential Address')}
        </span>
      </div>

      {renderView()}
    </div>
  )
}
