import {useTranslation} from '../../../../../hooks/useTranslation'
import {isContactInfoRoleAdmin, isSuperAdmin} from '../../../../../../KeycloakAdminService'
import {AdminDropdown} from '../../../reusable/admin_dropdown/AdminDropdown'
import {useContext, useEffect, useState} from 'react'
import USER_TYPE_COLORS from '../../../../common/constants/UserTypeConstant'
import styled from 'styled-components'
import {LanguageContext} from '../../../../../contexts/LanguageContext'
import {formatDateLocaleShort} from '../../../../../languages/datetimeformats'
import {beforeUnload} from '../../../../../App'
// Define the styled div component for UserTypeBadge
const StyledUserTypeBadge = styled.div<{userType: string}>`
  width: 90%;
  height: 31px;
  border-radius: 6px;
  text-align: center;
  line-height: 31px; /* Center vertically */
  color: ${(props) => USER_TYPE_COLORS[props.userType]?.color};
  background-color: ${(props) => USER_TYPE_COLORS[props.userType]?.backgroundColor};

  /* Text styles */
  font-size: 12px;
  font-weight: 700;
`

const ElectorsListRow = ({user, setUserToEdit, setEditUserModal, setEditUserModalView}) => {
  const {translateObj} = useTranslation()
  const LANGUAGE_CONTEXT = useContext(LanguageContext)

  const userFullName = user.voterInfo?.firstName + ' ' + user.voterInfo?.lastName
  const [userToView, setUserToView] = useState({voterInfo: {identification: []}})
  console.log(user)
  return (
    <div className='voterAddrList__tableBody__row'>
      {/* MEMBER ID */}
      {/* <span className='electorListTable__item--1'>{user.voterId}</span> */}
      {/* FULL NAME */}
      <span className='electorListTable__item--3'>{user.username}</span>
      <span className='electorListTable__item--3'>{userFullName}</span>
      <span className='electorListTable__item--2'>{user.voterInfo?.dateOfBirth}</span>
      <span className='electorListTable__item--3'>{user.voterInfo?.address?.streetAddress}</span>
      <span className='electorListTable__item--3'>{user.email}</span>
      <span className='electorListTable__item--3'>
        <span
          className={`importAddrList__statusLabel ${
            user.voterInfo?.status === 'Approved'
              ? 'importAddrList__statusLabel--success '
              : user.voterInfo?.status === 'Declined'
              ? 'importAddrList__statusLabel--failed '
              : 'importAddrList__statusLabel--na '
          }`}
        >
          {user.voterInfo?.status}
        </span>
      </span>
      <span className='electorListTable__item--3'>
        <span
          className={`importAddrList__statusLabel ${
            user.voteReceipts?.length > 1
              ? 'importAddrList__statusLabel--success '
              : user.accessLinkStatus === 'invalid'
              ? 'importAddrList__statusLabel--na '
              : ''
          }`}
        >
          {user.voteReceipts?.length > 1
            ? 'Voted'
            : user.accessLinkStatus === 'invalid'
            ? 'Onboarded'
            : ''}
        </span>
      </span>
      {/* TYPE */}

      {/* AdminDropdown component */}
      {(isSuperAdmin() || isContactInfoRoleAdmin()) && (
        <div className='electorListTable__item--9 row-item-ellipsis'>
          <AdminDropdown
            view='electorDecision'
            user={user}
            onCallback={(value) => {
              if (value == 'View') {
                if (user.voterInfo) {
                  setUserToView(user)
                  window.removeEventListener('beforeunload', beforeUnload)
                  window.open(`/admin/voters/${user?.voterInfo?._id}`, '_blank', 'noreferrer')
                  // history.push(`/admin/participants/${user?.voterInfo?._id}`)
                  // setIsViewIdModalOpen(true)
                } else {
                  alert('User not viewable')
                }
              } else {
                if (user.voterInfo.status === 'Approved') {
                  setUserToEdit(user)
                  setEditUserModalView(value) // Edit, Resend access email, Reset password
                  setEditUserModal(true)
                } else {
                  alert('User is not approved')
                }
              }
            }}
          />
        </div>
      )}
    </div>
  )
}

export default ElectorsListRow
