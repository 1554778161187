/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useEffect, useContext} from 'react'
import {useHistory, useLocation} from 'react-router-dom'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import {toAbsoluteUrl} from '../../../helperfunctions/AssetHelpers'
import './MEXICO__ResetPassword.scss'
import '@djthoms/pretty-checkbox'
import TextField from '../../../modules/auth/reusable/TextField'

import {useVoterStyles} from '../../../contexts/VoterStylesContext'
import {VoterAuthContext} from '../../../contexts/VoterAuthContext'

import {EmailSent} from './email_sent/EmailSent'
import {VoterLoginFlowContext} from '../../../contexts/VoterLoginFlowContext'

import VoterAuthService from '../../../services/auth/VoterAuthService'
import AdminVoterAuthService from '../../../services/auth/AdminVoterAuthService'
import {RequestSent} from './email_sent/RequestSent'
import {useGoogleReCaptcha} from 'react-google-recaptcha-v3'
import {useTranslation} from '../../../hooks/useTranslation'
import RecapatchaDisclaimer from '../../../resuable/recapatcha/RecapatchaDisclaimer'
import {addPeriodToText} from '../../common/utility_functions/TextUtility'

const initialValues = {
  username: '',
  email: '',
}

export function MEXICO__ResetPassword() {
  const {translate} = useTranslation()

  const history = useHistory()
  const location = useLocation()

  const pathname = location.pathname

  const {executeRecaptcha} = useGoogleReCaptcha()

  const [loading, setLoading] = useState(false)
  const {customBannerImg} = useVoterStyles()
  const VOTER_AUTH_CONTEXT = useContext(VoterAuthContext)
  const [currentView, setCurrentView] = useState('Forgot Password')

  const VOTER_LOGIN_CONTEXT = useContext(VoterLoginFlowContext)

  const loginSchema = Yup.object().shape({
    username: Yup.string().required(
      pathname.includes('admin')
        ? translate('Username is required')
        : translate('Username is required')
    ),
    email: Yup.string().required(translate('Email address is required')),
  })

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setTimeout(async () => {
        try {
          const userInput = {
            username: values.username,
            email: values.email,
          }
          if (!executeRecaptcha) {
            console.log('Execute recaptcha not yet available')
            return
          }

          if (pathname.includes('admin')) {
            const token = await executeRecaptcha('admin_passwordreset')

            let result = await AdminVoterAuthService.requestPasswordReset(
              userInput.username,
              userInput.email,
              token
            )
            if (result.success) {
              setCurrentView('Request Sent Admin')
            }
          } else {
            const token = await executeRecaptcha('voter_passwordreset')

            let result = await VoterAuthService.requestPasswordReset(
              userInput.username,
              userInput.email,
              token
            )
            if (result.success) {
              setCurrentView('Email Sent')
            }
          }

          setLoading(false)
        } catch (error) {
          console.log(error)
        }
      }, 10)
    },
  })

  const ForgotPassword = (
    <>
      <span className='forgotPassword__form__title'>{translate('Forgot password')}</span>
      {/* Password section */}
      <form
        className='forgotPassword__form__body'
        onSubmit={(e) => {
          e.preventDefault()
          formik.submitForm()
        }}
      >
        <div>
          <div className='mb-2'>
            <TextField
              formik={formik}
              type='text'
              field='username'
              placeholder={
                pathname.includes('admin') ? translate('Username') : translate('Username')
              }
              label={pathname.includes('admin') ? translate('Username') : translate('Username')}
              isRequired={true}
              view='voter'
            />
          </div>

          {formik.touched['username'] && formik.errors['username'] && (
            <div className='TextField__voterErrWrap'>
              <span className='TextField__voterErrTxt' role='alert'>
                {addPeriodToText(formik.errors['username'])}
              </span>
            </div>
          )}

          <div className='createPassword__form__body__password__confirmInput'>
            <div className='mb-2'>
              <TextField
                formik={formik}
                type='email'
                field='email'
                placeholder={translate('Email address')}
                label={translate('Email address')}
                isRequired={true}
                view='voter'
              />
            </div>

            {formik.touched['email'] && formik.errors['email'] && (
              <div className='TextField__voterErrWrap'>
                <span className='TextField__voterErrTxt' role='alert'>
                  {addPeriodToText(formik.errors['email'])}
                </span>
              </div>
            )}
          </div>
        </div>
      </form>
      {/* FOOTER  */}
      <div className='userLogin__form__footer'>
        <button
          type='button'
          onClick={() => {
            formik.submitForm()
            if (pathname.includes('admin')) {
              VOTER_LOGIN_CONTEXT.setLoginFlow('Admin Forgot Password')
            } else {
              VOTER_LOGIN_CONTEXT.setLoginFlow('Forgot Password')
            }
          }}
          className='userLogin__form__footer__btn'
        >
          {translate('Reset Password')}
        </button>
      </div>
      <div className='forgotPassword__form__bottom'>
        <span className='forgotPassword__form__bottom__label'>
          {translate('Cancel and go to')}{' '}
          <span
            className='common__link common__fw500'
            onClick={() => {
              if (pathname.includes('admin')) {
                history.push('/admin/login/')
              } else {
                history.push('/voting/voter-login/')
              }
            }}
          >
            {translate('Login page')}
          </span>
        </span>
      </div>
    </>
  )

  const renderView = () => {
    if (currentView == 'Forgot Password') {
      return ForgotPassword
    }
    if (currentView == 'Email Sent') {
      return <EmailSent setCurrentView={setCurrentView} />
    }
    if (currentView == 'Request Sent Admin') {
      return <RequestSent setCurrentView={setCurrentView} />
    }
  }

  useEffect(() => {
    renderView()
  }, [currentView])

  return (
    <div className='VerifyMethod' id='Voter'>
      {/* FORM */}
      <div className='verifyMethod__formWrapper'>
        <img
          alt='banner'
          className='voterRegister__topBanner'
          src={toAbsoluteUrl(
            customBannerImg ? customBannerImg : '/media/design/voter_ns_top_banner.png'
          )}
        />

        <div className='resetPassword__form'>{renderView()}</div>
      </div>

      <RecapatchaDisclaimer addClassName='recaptcha__disclaimerMobile--mt400' view='mobile' />
    </div>
  )
}
