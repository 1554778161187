// WelcomeMessage.js
import React, {useContext} from 'react'
import styled from 'styled-components'
import {useTranslation} from '../../../../../../hooks/useTranslation'
import {VoterUserContext} from '../../../../../../contexts/VoterUserContext'
import {useSuperAdminUserContext} from '../../../../../../contexts/SuperAdminUserContext'

// Styled component for the welcome message container
const WelcomeMessageContainer = styled.div`
  font-weight: 600;
  font-size: 18px;
`

const WelcomeMessage = () => {
  const {translate} = useTranslation()
  const VoterUser = useContext(VoterUserContext)
  const {adminUser} = useSuperAdminUserContext()
  const username = adminUser?.username || `${VoterUser?.user?.username}`

  return (
    <WelcomeMessageContainer>
      <span className='fw-500'>{translate('Hi')},</span> {username}
    </WelcomeMessageContainer>
  )
}

export default WelcomeMessage
