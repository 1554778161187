import {getDisplayAdress} from '../../../../../../../helperfunctions/AddressFormat'
import {AdminDropdown} from '../../../../../../voter_admin/reusable/admin_dropdown/AdminDropdown'
import './ParticipantDuplication.scss'
export const EligibleMatch = ({profiles}) => {
  return (
    <div className='ParticipantDuplication'>
      <span className='participantDuplication__label'>Eligible Voter List Match</span>

      {/* table */}
      <div className='participantDuplication__table'>
        {/* header */}
        <div className='participantDuplication__table__header'>
          <span className='participantDuplication__table__header__label participantDuplication__item'>
            Elector Id
          </span>
          <span className='participantDuplication__table__header__label participantDuplication__item'>
            Name
          </span>
          <span className='participantDuplication__table__header__label participantDuplication__item'>
            Date of Birth
          </span>
          <span className='participantDuplication__table__header__label participantDuplication__item'>
            Address
          </span>
          <span className='participantDuplication__table__header__label participantDuplication__item'>
            Registration Status
          </span>
        </div>

        {/* body */}
        <div className='participantDuplication__table__body'>
          {profiles.map((profile, index) => {
            return (
              <div className='participantDuplication__table__body__row'>
                <span className='participantDuplication__table__body__row__value participantDuplication__item'>
                  {profile.electorId}
                </span>
                <span className='participantDuplication__table__body__row__value participantDuplication__item'>
                  {profile.firstName} {profile.middleName} {profile.lastName}
                </span>
                <span className='participantDuplication__table__body__row__value participantDuplication__item'>
                  {profile.dateOfBirth}
                </span>
                <span className='participantDuplication__table__body__row__value participantDuplication__item'>
                  {getDisplayAdress(profile.address)}
                </span>
                <span
                  className='participantDuplication__table__body__row__value participantDuplication__item'
                  style={{fontWeight: 800}}
                >
                  {profile.registered ? 'Registered' : ''}
                </span>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}
