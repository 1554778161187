import Modal from 'react-modal'
import {ModalHeader} from '../../modals/ModalHeader'
import './CustomModalWrapper.scss'
import {ModalHeaderWithLogo} from '../../voter/components/layout/ModalHeaderLogo'
import CustomModalStyles from '../../../constants/ModalStyles'

const CustomModalWrapper = ({
  isOpen,
  onClose,
  headerLabel = '',
  headerLogo = false,
  modalStyles = null,
  children,
}) => {
  return (
    <Modal
      ariaHideApp={false}
      isOpen={isOpen}
      style={modalStyles ? modalStyles : CustomModalStyles}
    >
      <div className='CustomModalWrapper' style={{flexGrow: 1}}>
        {headerLabel && <ModalHeader onRequestClose={onClose} headerLabel={headerLabel} />}

        {headerLogo && <ModalHeaderWithLogo />}

        {children}
      </div>
    </Modal>
  )
}

export default CustomModalWrapper
