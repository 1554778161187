// paths reused often
const ROUTE_PATHS = {
  HOME: '/',
  LANDING: '/',
  REGISTRATION_START: '/voting/register',
  REGISTRATION_VERIFY: '/voting/register-complete',
  LOGIN: '/voting/voter-login',
  // jumio
  // REGISTRATION_START: '/voting/voter-auth/register-start',

  /// old civik
}

export default ROUTE_PATHS
