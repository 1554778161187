import TextField from '../../../../../app/modules/auth/reusable/TextField'
import '../../pages/VoterRegister.scss'
import {useVoterStyles} from '../../../../contexts/VoterStylesContext'
import {toAbsoluteUrl} from '../../../../helperfunctions/AssetHelpers'
import './ContactInfo.scss'
import {useEffect, useState} from 'react'
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import CheckBox from '../../../reusable/check_box/CheckBox'
import {useTranslation} from '../../../../hooks/useTranslation'
import FormikError from '../../../reusable/formik_error/FormikError'

export function ContactInfo({formik, view, customStyling = {}}) {
  const {customTxtClass} = useVoterStyles()
  const [onAgreeToSMSnotif, setOnAgreeToSMSnotif] = useState(false)
  const {translate} = useTranslation()

  const [phoneNumber, setPhoneNumber] = useState('')
  const handlePhoneNumberChange = (newPhoneNumber) => {
    setPhoneNumber(newPhoneNumber)

    console.log('newPhoneNumber', newPhoneNumber)

    formik.setFieldValue('phoneNumber', newPhoneNumber)
  }

  useEffect(() => {
    // handle state change when clicked back to step 1
    if (formik.values.receiveSMSNotif) {
      setOnAgreeToSMSnotif(true)
    }

    if (formik.values.phoneNumber) {
      setPhoneNumber(formik.values.phoneNumber)
    }
  }, [formik.values.receiveSMSNotif])

  const VoterRegister = (
    <>
      <div className='voterRegister__emailInputsWrap'>
        <div className='voterRegister__emailWrap'>
          <TextField
            formik={formik}
            type='text'
            field='email'
            placeholder={translate('Email Address')}
            label={translate('Email Address')}
            isRequired={true}
            view='voter'
          />
          {formik.errors['email'] || formik.errors['confirmEmail'] ? (
            <div className='voterRegister__singleInputErr'>
              <div className='TextField__voterErrWrap'>
                <span className='TextField__voterErrTxt' role='alert'>
                  {translate('Email Address is required!')}
                </span>
              </div>
            </div>
          ) : null}

          {formik.values.email !== formik.values.confirmEmail ? (
            <div className='voterRegister__singleInputErr'>
              <div className='TextField__voterErrWrap'>
                <span className='TextField__voterErrTxt' role='alert'>
                  {translate("Emails don't match!")}
                </span>
              </div>
            </div>
          ) : null}
        </div>

        <div className='voterRegister__confirmEmailWrap'>
          <TextField
            formik={formik}
            type='text'
            field='confirmEmail'
            placeholder={translate('Confirm Email Address')}
            label={translate('Confirm Email Address')}
            isRequired={true}
            view='voter'
          />
        </div>
      </div>
      <div className='voterRegister__phoneWrap'>
        <label className='form-label fs-6 fw-bold text-dark required'>
          {translate('Cell Phone Number')}
        </label>
        <div className='voterRegister__phoneInputWrap'>
          <PhoneInput
            {...formik.getFieldProps('phoneNumber')}
            name='phoneNumber'
            defaultCountry='CA'
            className='authMethod__phoneConatiner'
            placeholder={translate('Cell Phone Number')}
            value={formik.values.phoneNumber}
            onChange={handlePhoneNumberChange}
          />
          <FormikError
            formik={formik}
            fieldName='phoneNumber'
            errorLabel={translate('Phone number is required!')}
          />
        </div>
        <CheckBox
          checked={onAgreeToSMSnotif}
          onChange={(value) => {
            formik.setFieldError('receiveSMSNotif', '')
            setOnAgreeToSMSnotif(value)
            formik.values.receiveSMSNotif = value
          }}
          error={null}
          label={translate(
            'I consent to receiving communication about this process through text messages and/or email.'
          )}
        />
        {formik.errors['receiveSMSNotif'] && (
          <div className='voterRegister__singleInputErr'>
            <div className='TextField__voterErrWrap'>
              <span className='TextField__voterErrTxt' role='alert'>
                {translate('Communications are required.')}
              </span>
            </div>
          </div>
        )}
      </div>
    </>
  )

  const AdminEdit = (
    <>
      <div className='voterRegister__emailInputsWrap'>
        <div className='voterRegister__emailWrap'>
          <TextField
            formik={formik}
            type='text'
            field='email'
            placeholder={translate('Email Address')}
            label={translate('Email Address')} // Changed to use translate
            isRequired={true}
            view='voter'
          />
          {(formik.touched['email'] && formik.errors['email']) ||
          (formik.touched['confirmEmail'] && formik.errors['confirmEmail']) ? (
            <div className='voterRegister__singleInputErr'>
              <div className='TextField__voterErrWrap'>
                <span className='TextField__voterErrTxt' role='alert'>
                  {translate('Email Address is required!')}
                </span>
              </div>
            </div>
          ) : null}

          {formik.values.email !== formik.values.confirmEmail ? (
            <div className='voterRegister__singleInputErr'>
              <div className='TextField__voterErrWrap'>
                <span className='TextField__voterErrTxt' role='alert'>
                  {translate("Emails don't match!")}
                </span>
              </div>
            </div>
          ) : null}
        </div>

        <div className='voterRegister__phoneInputWrap'>
          <TextField
            formik={formik}
            type='text'
            field='phoneNumber'
            placeholder={translate('Phone Number')}
            label={translate('Phone Number')}
            isRequired={true}
            view='voter'
          />
          <FormikError
            formik={formik}
            fieldName='phoneNumber'
            errorLabel={translate('Phone number is required!')}
          />
        </div>
      </div>
    </>
  )

  return (
    <div className='voterRegister__contactRow' style={customStyling}>
      <div className='voterRegister__divider'>
        <span
          className={
            customTxtClass
              ? `voterRegister__dividerLabel ${customTxtClass}`
              : 'voterRegister__dividerLabel'
          }
        >
          {translate('Contact Information')}
        </span>
      </div>
      {view === 'VoterRegister' ? VoterRegister : null}
      {view === 'AdminEdit' ? AdminEdit : null}
    </div>
  )
}
