import {useEffect, useState} from 'react'
import {toAbsoluteUrl} from '../../../../../../../helperfunctions/AssetHelpers'
import {CustomBtn} from '../../../../../../reusable/custom_btn/CustomBtn'
import './ParticipantPhotoInfo.scss'
import {PhotoIdModal} from '../view_photo_id_modal/PhotoIdModal'
import {UploadPhotoID} from '../../../../../../voter/components/registration/UploadPhotoID'
import {useHistory} from 'react-router-dom'

export const ParticipantPhotoInfo = ({photos}) => {
  const history = useHistory()
  const [modalView, setModalView] = useState('view-photo-id')
  const [modalPhotoIdOpen, setModalPhotoIdOpen] = useState(false)
  const [currentPhotoIndex, setCurrentPhotoIndex] = useState(0)

  const onViewPDF = async ({url}) => {
    const encodedPDFURL = encodeURIComponent(url)
    history.push(`/admin/view-pdf/${encodedPDFURL}`)
  }

  function hasPdfExtension(filename) {
    return filename.toLowerCase().endsWith('.pdf')
  }
  return (
    <div className='ParticipantPhotoInfo'>
      {photos?.length ? (
        <PhotoIdModal
          modalOpen={modalPhotoIdOpen}
          setModalOpen={setModalPhotoIdOpen}
          modalView={modalView}
          setModalView={setModalView}
          photos={photos}
          i={currentPhotoIndex}
        />
      ) : null}
      <div className='participantPhotoInfo__photo'>
        <span className='participantPhotoInfo__photo__label'>Photo ID</span>
        <div className='participantPhotoInfo__photos'>
          {photos.map((photo, index) => {
            return (
              <div>
                <img
                  key={index}
                  className='participantPhotoInfo__photo__img'
                  style={{cursor: 'pointer'}}
                  alt='identification'
                  src={photo.fileUrl}
                  onClick={() => {
                    if (hasPdfExtension(photo.filename)) {
                      onViewPDF({url: photo.fileUrl})
                    } else {
                      setModalView('view-photo-id')
                      setCurrentPhotoIndex(index)
                      setModalPhotoIdOpen(true)
                    }
                  }}
                />
              </div>
            )
          })}
        </div>
        <div className='participantPhotoInfo__btns'>
          <div className='participantPhotoInfo__btn--uploadNew'>
            {/* <CustomBtn
              view='ActionBtn'
              text={'Upload New'}
              height={48}
              width={174}
              onClick={() => {
                setModalUploadOpen(true)
              }}
            /> */}

            {/* <CustomBtn
              view='PrimaryBtn'
              text={'View'}
              height={48}
              width={174}
              // customStyling={{marginLeft: 25}}
              onClick={() => {
                setModalView('view-photo-id')
                setModalPhotoIdOpen(true)
              }}
            /> */}
          </div>
        </div>
      </div>
    </div>
  )
}
