import {useState, useContext} from 'react'
import {Switch, Route, Redirect} from 'react-router-dom'
import {VoterAdminRoutes} from './VoterAdminRoutes'
import {AdminSidebar} from '../pages/voter_admin/reusable/admin_sidebar/AdminSidebar'
import {AdminTopbar} from '../pages/voter_admin/reusable/admin_topbar/AdminTopbar'

import {MEXICO__UserLogin} from '../pages/voter_admin/pages/mexico/user_login/MEXICO__UserLogin'
import {VoterOnboardingContext} from '../contexts/VoterOnboardingContext'
import {AdminNavbarMobile} from '../pages/voter_admin/reusable/admin_navbar_mobile/AdminNavbarMobile'
import {MEXICO__ResetPassword} from '../pages/public/reset_password/MEXICO__ResetPassword'
import {VoterNavbar} from '../pages/voter/reusable/VoterNavbar'
import {VoterNavbarMobile} from '../pages/voter/reusable/VoterNavbarMobile'
import VoteReceipt from '../pages/VoteReceipt/VoteReceipt'
import {ChangePassword} from '../pages/public/reset_password/change_password/ChangePassword'
import RecapatchaDisclaimer from '../resuable/recapatcha/RecapatchaDisclaimer'
import {MEXICO__PhoneVerify} from '../pages/public/onboarding/phone_verify/MEXICO__PhoneVerify'
import {MEXICO__AuthMethod} from '../pages/public/onboarding/auth_method/MEXICO__AuthMethod'
import {MEXICO__AuthAppSetup} from '../pages/public/onboarding/auth_app_setup/MEXICO__AuthAppSetup'
import {MEXICO__AuthVerify} from '../pages/public/onboarding/auth_verify/MEXICO__AuthVerify'
import {MEXICO__CreatePassword} from '../pages/public/onboarding/create_password/MEXICO__CreatePassword'
import {MEXICO__AuthMethodLogin} from '../pages/public/onboarding/auth_method_login/MEXICO__AuthMethodLogin'
import {VoterLandingPage} from '../pages/voter/pages/VoterLandingPage'
import {VoterRegister} from '../pages/voter/pages/VoterRegister'
import {VoterRegistrationUserContext} from '../contexts/RegistrationUser'
import {VoterRegisterFollowUp} from '../pages/voter/components/registration_follow_up/VoterRegisterFollowUp'
const OnboardingRoutes = (props) => {
  return (
    <Switch>
      <Route path='/voting/voter-onboarding/phone-verify' component={MEXICO__PhoneVerify} />
      <Route path='/voting/voter-onboarding/auth-method' component={MEXICO__AuthMethod} />
      <Route path='/voting/voter-onboarding/auth-app-setup' component={MEXICO__AuthAppSetup} />
      <Route path='/voting/voter-onboarding/auth-verify' component={MEXICO__AuthVerify} />
      <Route path='/voting/voter-onboarding/create-password' component={MEXICO__CreatePassword} />
      <Route path='*' render={() => <Redirect to='/voting/voter-login' />} />
    </Switch>
  )
}

const VoterRoutes = (props) => {
  const {adminView} = props
  const [authMethod, setAuthMethod] = useState(null)
  const [registrationUser, setRegistrationUser] = useState(null)
  if (!adminView) {
    return (
      <div
        style={{
          maxWidth: '100%',
          height: '100%',
          display: 'flex',
          width: '100%',
          flexFlow: 'column',
        }}
      >
        <VoterNavbar />

        <VoterOnboardingContext.Provider value={{authMethod, setAuthMethod}}>
          {/* Switch from parent */}
          <Route path='/' component={VoterLandingPage} exact />

          <Route path='/voting/voter-login'>
            <Switch>
              <Route path='/voting/voter-login' component={MEXICO__UserLogin} exact />
              <Route path='/voting/voter-login/:eventId' component={MEXICO__UserLogin} />
              <Route path='*' render={() => <Redirect to='/voting/voter-login/' />} />
            </Switch>
          </Route>
          <Route path='/voting/voter-verify'>
            <Switch>
              <Route
                path='/voting/voter-verify/select-verifier'
                component={MEXICO__AuthMethodLogin}
              />
              <Route path='/voting/voter-verify/verify' component={MEXICO__AuthVerify} />
              <Route path='*' render={() => <Redirect to='/voting/voter-login/' />} />
            </Switch>
          </Route>
          <Route path='/voting/voter-auth'>
            <Switch>
              <Route
                path='/voting/voter-auth/reset-password'
                component={MEXICO__ResetPassword}
                exact
              />
              <Route path='/voting/voter-auth/change-password' component={ChangePassword} exact />
              <Route path='*' render={() => <Redirect to='/voting/voter-login/' />} />
            </Switch>
          </Route>

          <Route path='/voting/voter-onboarding'>
            <OnboardingRoutes />
          </Route>
          <VoterRegistrationUserContext.Provider value={{registrationUser, setRegistrationUser}}>
            <Route path='/voting/register' component={VoterRegister} />
            <Route path='/voting/register-complete' component={VoterRegisterFollowUp} />
          </VoterRegistrationUserContext.Provider>

          {/* end switch from parent */}
        </VoterOnboardingContext.Provider>

        <RecapatchaDisclaimer />
      </div>
    )
  }

  return (
    <div style={{maxWidth: '100%', display: 'flex', width: '100%', flexFlow: 'column'}}>
      <AdminSidebar />
      <AdminTopbar />
      <AdminNavbarMobile />
      {/* Switch from parent */}
      <VoterAdminRoutes />
      <Route path='/election' component={VoteReceipt} />
      {/* end switch from parent */}
    </div>
  )
}

export {VoterRoutes}
