import {useTranslation} from '../../../../../../../hooks/useTranslation'
import './ParticipantContactInfo.scss'
export const ParticipantContactInfo = ({dob, phone, email}) => {
  const {translate} = useTranslation()
  return (
    <div className='ParticipantContactInfo'>
      <div className='participantContactInfo__dob'>
        <span className='participantContactInfo__dob__label'>{translate('Date of Birth')}</span>
        <span className='participantContactInfo__dob__value'>{dob}</span>
      </div>

      <div className='participantContactInfo__phone'>
        <span className='participantContactInfo__phone__label'>{translate('Phone Number')}</span>
        <span className='participantContactInfo__phone__value'>{phone}</span>
      </div>

      <div className='participantContactInfo__email'>
        <span className='participantContactInfo__email__label'>{translate('Email Address')}</span>
        <span className='participantContactInfo__email__value'>{email}</span>
      </div>
    </div>
  )
}
