import React, {useEffect, useState} from 'react'

import TextField from '../../../../../app/modules/auth/reusable/TextField'
import SelectField from '../../../../../app/modules/auth/reusable/SelectField'

import '../../pages/VoterRegister.scss'
import useMobileView from '../../../../hooks/UseMobileView'
import CheckBox from '../../../reusable/check_box/CheckBox'
import {useTranslation} from '../../../../hooks/useTranslation'

const calendarMonths = [
  {value: 0, label: 'Month'},
  {value: 'January', label: 'January'},
  {value: 'February', label: 'February'},
  {value: 'March', label: 'March'},
  {value: 'April', label: 'April'},
  {value: 'May', label: 'May'},
  {value: 'June', label: 'June'},
  {value: 'July', label: 'July'},
  {value: 'August', label: 'August'},
  {value: 'September', label: 'September'},
  {value: 'October', label: 'October'},
  {value: 'November', label: 'November'},
  {value: 'December', label: 'December'},
]

export function UserInfo(props) {
  const {formik, customStyling, onFormatBirthDate = {}, changeSchema, hasSingleName} = props
  const isMobileSize = useMobileView()
  const [loading, setLoading] = useState(false)
  const [singleName, setSingleName] = useState(false)

  const [formattedDays, setFormattedDays] = useState([{value: 0, label: 'Day'}])
  const {translate} = useTranslation()

  var getDaysInMonth = function (month, year) {
    // Here January is 1 based
    //Day 0 is the last day in the previous month
    return new Date(year, month, 0).getDate()
    // Here January is 0 based
    // return new Date(year, month+1, 0).getDate();
  }

  const formatDays = (monthIndex) => {
    let currentYear = new Date().getFullYear()
    let maxDays = getDaysInMonth(monthIndex, currentYear)

    let daysList = [{value: 0, label: 'Day'}]

    for (let i = 1; i <= maxDays; i++) {
      daysList.push({value: i, label: i.toString()})
    }

    return daysList
  }

  const formatYears = () => {
    let currentYear = new Date().getFullYear()
    let legalAgeMax = currentYear - 18
    let yearsList = [{value: 0, label: 'Year'}]

    for (let i = legalAgeMax; i >= 1910; i--) {
      yearsList.push({value: i, label: i.toString()})
    }
    return yearsList
  }
  function getMonthIndexByName(monthName) {
    // Find the index of the month object where the label matches the monthName argument
    const monthIndex = calendarMonths.findIndex((month) => month.label === monthName)
    // Return the index if found, otherwise return -1
    return monthIndex
  }

  const handleSingleNameChange = () => {
    if (singleName) {
      props.onSingleName(false)
      setSingleName(false)
      formik.values.singleName = false
    } else {
      props.onSingleName(true)
      // set single name only
      setSingleName(true)
      formik.values.singleName = true
      // clear middle name and last name
      formik.values.middleName = ''
      formik.values.lastName = ''
      // clear last name errors
      formik.touched.lastName = false
      formik.setFieldError('lastName', '')
    }
  }

  useEffect(() => {
    if (formik.values.birthMonth) {
      let monthIndex = getMonthIndexByName(formik.values.birthMonth)
      setFormattedDays(formatDays(monthIndex))
    }
  }, [formik.values.birthMonth])

  useEffect(() => {
    if (hasSingleName) {
      setSingleName(true)
    }
  }, [hasSingleName])

  return (
    <div className='voterRegister__bascInfoSection' style={customStyling}>
      <div className='voterRegister__nameRow'>
        <div className='voterRegister__nameInputsWrap'>
          <TextField
            formik={formik}
            type='text'
            field='firstName'
            placeholder={translate('First Name')}
            label={translate('First Name')}
            isRequired={true}
            view='voter'
          />
          <div className='voterRegister__middleNameWrap'>
            <TextField
              formik={formik}
              type='text'
              field='middleName'
              placeholder={translate('Middle Name')}
              label={translate('Middle Name')}
              isRequired={false}
              view='voter'
              disabled={singleName}
            />{' '}
          </div>
          <TextField
            formik={formik}
            type='text'
            field='lastName'
            placeholder={translate('Last Name')}
            label={translate('Last Name')}
            isRequired={singleName ? false : true}
            view='voter'
            disabled={singleName}
          />{' '}
        </div>

        <div className='' onClick={handleSingleNameChange}>
          <CheckBox
            checked={singleName}
            onChange={(value) => {
              if (value) {
                changeSchema('step1--singleName')
              } else {
                changeSchema('step1')
              }
            }}
            error={null}
            containerStyling={{marginTop: 10}}
            label={translate('Single Name? (I have a legally registered single name)')}
            labelStyling={{fontWeight: '500'}}
          />
        </div>

        {/* ERROR MESSAGES */}
        <div
          //add spacing based on if there was input errors
          className={
            formik.errors['firstName'] || formik.errors['lastName']
              ? 'voterRegister__inputErrorsWrap'
              : ''
          }
        >
          {formik.errors['firstName'] && (
            <div className='TextField__voterErrWrap'>
              <span className='TextField__voterErrTxt' role='alert'>
                {translate(formik.errors['firstName'])}
              </span>
            </div>
          )}

          {formik.errors['lastName'] && (
            <div className='TextField__voterErrWrap'>
              <span className='TextField__voterErrTxt' role='alert'>
                {translate(formik.errors['lastName'])}
              </span>
            </div>
          )}
        </div>
      </div>

      {/* DATE OF BIRTH ROW */}
      <div className='voterRegister__birthRow'>
        <div className='voterRegister__selectedMonthWrap'>
          <SelectField
            width={isMobileSize ? '100%' : 172}
            formik={formik}
            type='text'
            field='birthMonth'
            placeholder={translate('Month')}
            label={translate('Date of Birth')}
            isRequired={true}
            options={calendarMonths.map((month) => ({...month, label: translate(month.label)}))}
            view='voter'
            customOnChange={(e) => {
              formik.setFieldValue('birthMonth', e.target.value)
              let monthIndex = calendarMonths.map((item) => item.value).indexOf(e.target.value)
              setFormattedDays(formatDays(monthIndex))
            }}
          />
          {(formik.errors['birthMonth'] ||
            formik.errors['birthDay'] ||
            formik.errors['birthYear']) && (
            <div className='SelectField__voterErrWrap'>
              <span className='TextField__voterErrTxt' role='alert'>
                {translate('Date of birth is required!')}
              </span>
            </div>
          )}
        </div>

        <div className='voterRegister__selectedDayWrap'>
          <SelectField
            width={isMobileSize ? '100%' : 172}
            formik={formik}
            type='text'
            field='birthDay'
            placeholder={translate('Day')}
            label=''
            isRequired={true}
            noLabel={true}
            options={formattedDays.map((day) => ({...day, label: translate(day.label)}))}
            view='voter'
          />
        </div>

        <div className='voterRegister__selectedDayWrap'>
          <SelectField
            width={isMobileSize ? '100%' : 172}
            formik={formik}
            type='text'
            field='birthYear'
            placeholder={translate('Year')}
            label=''
            isRequired={true}
            noLabel={true}
            options={formatYears().map((year) => ({...year, label: translate(year.label)}))}
            view='voter'
          />
        </div>
      </div>
    </div>
  )
}
