// CheckBox.js
import React from 'react'
import './CheckBox.scss'

const CheckBox = ({
  view = '',
  checked,
  onChange,
  error,
  label,
  labelStyling = {},
  containerStyling = {},
}) => {
  const handleClick = () => {
    onChange(!checked)
  }

  const CircleCheck = (
    <div
      style={containerStyling}
      className='common__radioBtnContainer authMethod__form__body__methodWrap__select--sms'
      onClick={handleClick}
    >
      <div className={checked ? 'common__radioBtnActive' : 'common__radioBtnDefault'}></div>
      <span className='authMethod__form__body__methodWrap__selectTxt' style={labelStyling}>
        {label}
      </span>
    </div>
  )

  const PrimaryCheck = (
    <div className='CheckBox' onClick={handleClick} style={containerStyling}>
      <div className='checkBox__input'>
        <input
          className='checkBox__input__field form-check-input'
          type='checkbox'
          checked={checked}
        />

        <span className='checkBox__input__label' style={labelStyling}>
          {label}
        </span>
      </div>
      {error && <span className='CheckBox__errorTxt'>{error}</span>}
    </div>
  )

  const renderView = () => {
    if (view === 'CircleCheck') {
      return CircleCheck
    } else {
      return PrimaryCheck
    }
  }

  return renderView()
}

export default CheckBox
