import {useTranslation} from '../../../../../hooks/useTranslation'
import {isContactInfoRoleAdmin, isSuperAdmin} from '../../../../../../KeycloakAdminService'

export const ElectorsListTableHeader = (props) => {
  const {translate} = useTranslation()
  return (
    <div className='voterAddrList__tableHeader'>
      {/* <span className='table-header-label electorListTable__item--1'>
        {translate('Elector Id')}
      </span> */}

      <span className='table-header-label electorListTable__item--3'>{translate('Username')}</span>
      <span className='table-header-label electorListTable__item--3'>{translate('Name')}</span>
      <span className='table-header-label electorListTable__item--2'>{translate('DOB')}</span>
      <span className='table-header-label electorListTable__item--3'>{translate('Address')}</span>
      <span className='table-header-label electorListTable__item--3'>{translate('Email')}</span>
      <span className='table-header-label electorListTable__item--3'>
        {translate('Registration Status')}
      </span>
      <span className='table-header-label electorListTable__item--3'>{translate('Voted')}</span>

      {(isSuperAdmin() || isContactInfoRoleAdmin()) && (
        <span className='table-header-label electorListTable__item--9'>{translate('Action')}</span>
      )}
    </div>
  )
}
export default ElectorsListTableHeader
