import {toAbsoluteUrl} from '../../../../helperfunctions/AssetHelpers'
import {useVoterStyles} from '../../../../contexts/VoterStylesContext'
import './ModalHeaderLogo.scss'

export const ModalHeaderWithLogo = () => {
  const {customLogoImg} = useVoterStyles()

  return (
    <div className='ModalHeaderLogo'>
      <img alt='logo' src={toAbsoluteUrl(customLogoImg)} className='modalHeaderLogo__logo' />
    </div>
  )
}
