import {getDisplayAdress} from '../../../../../../../helperfunctions/AddressFormat'
import {AdminDropdown} from '../../../../../../voter_admin/reusable/admin_dropdown/AdminDropdown'
import './ParticipantDuplication.scss'
export const ParticipantDuplication = ({profiles}) => {
  return (
    <div className='ParticipantDuplication'>
      <span className='participantDuplication__label'>Similar Registrants</span>

      {/* table */}
      <div className='participantDuplication__table'>
        {/* header */}
        <div className='participantDuplication__table__header'>
          <span className='participantDuplication__table__header__label participantDuplication__item2'>
            Name
          </span>
          <span className='participantDuplication__table__header__label participantDuplication__item2'>
            Date of Birth
          </span>
          <span
            className='participantDuplication__table__header__label participantDuplication__item2'
            style={{width: '20%'}}
          >
            Address
          </span>
          <span className='participantDuplication__table__header__label participantDuplication__item2'>
            Status
          </span>
          <span
            className='participantDuplication__table__header__label participantDuplication__item2'
            style={{width: '10%'}}
          >
            Action
          </span>
        </div>

        {/* body */}
        <div className='participantDuplication__table__body'>
          {profiles.map((profile, index) => {
            return (
              <div className='participantDuplication__table__body__row'>
                <span className='participantDuplication__table__body__row__value participantDuplication__item2'>
                  {profile.firstName} {profile.middleName} {profile.lastName}
                </span>
                <span className='participantDuplication__table__body__row__value participantDuplication__item2'>
                  {profile.dateOfBirth}
                </span>
                <span
                  className='participantDuplication__table__body__row__value participantDuplication__item2'
                  style={{width: '20%'}}
                >
                  {getDisplayAdress(profile.address)}
                </span>
                <span
                  className={`participantDuplication__table__body__row__value  importAddrList__statusLabel ${
                    profile.status === 'Approved'
                      ? 'importAddrList__statusLabel--success '
                      : profile.status === 'Declined'
                      ? 'importAddrList__statusLabel--failed '
                      : 'importAddrList__statusLabel--na '
                  } participantDuplication__item2`}
                >
                  {profile.status}
                </span>
                <div
                  className='participantDuplication__table__body__row__value participantDuplication__item2'
                  style={{width: '10%', paddingRight: '5%'}}
                >
                  <AdminDropdown
                    customOptionStyling={{position: 'absolute'}}
                    view='customDropDown'
                    dropdownOptions={[{label: 'View', value: 'View'}]}
                    onCallback={(value) => {
                      if (value == 'View') {
                        window.open(`/admin/voters/${profile._id}`, '_blank', 'noreferrer')
                      }
                    }}
                  />
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}
