import {toAbsoluteUrl} from '../../../helperfunctions/AssetHelpers'

import ROUTE_PATHS from '../../../routing/RoutePaths'

import {useVoterStyles} from '../../../contexts/VoterStylesContext'

import '../pages/VoterRegister.scss'

import './VoterNavbar.scss'
import {useHistory, useLocation} from 'react-router-dom'
import LanguagesDropdown from '../../../components/languages_dropdown/LanguagesDropdown'

export function VoterNavbar(props) {
  const history = useHistory()
  const {customLogoImg} = useVoterStyles()
  const location = useLocation()
  const currentPathname = location.pathname

  return (
    <div className='voter__navbar'>
      <img className='voter__navbar__logo' src={toAbsoluteUrl(customLogoImg)} alt='' />

      <div className='voterNavbar__rightContainer'>
        <LanguagesDropdown />

        {/* {currentPathname == ROUTE_PATHS.LANDING && (
          <div
            className='voterNavbar__loginBtn'
            onClick={() => {
              history.push('/voting/voter-login')
            }}
          >
            <span className='voterNavbar__loginBtn__label'>Login</span>
          </div>
        )} */}
      </div>
    </div>
  )
}
