export const translation = {
  registration: {
    electionTitleLine1: '2025 Hamilton Wentworth',
    electionTitleLine2: 'Conseil scolaire de district',
    electionTitleLine3: 'Élection partielle des administrateurs du quartier 4',

    voterRegistration: 'Inscription des électeurs',
    howToVote: 'Comment voter en ligne ?',
    // TODO change french
    onlineVoterRequirements:
      'Les électeurs en ligne doivent remplir un court formulaire d’inscription. Les inscriptions débutent le 1er décembre 2024. Veuillez revenir ici à ce moment-là pour vous inscrire.',
    //TODO add french
    onlineVoterRequirementsOpen:
      'Les électeurs en ligne doivent remplir un court formulaire d’inscription.',
    helpInfo1: `
La Ville de Hamilton recueille des renseignements en vertu de l'article 227 de la
Loi de 2001 sur les municipalités et article 52(1) de la Loi sur les élections municipales de 1996 et Ontario
Rég. 304/13. Toutes les informations personnelles collectées pour l'inscription des électeurs en ligne seront utilisées
à dans le but de confirmer l’éligibilité des électeurs. Informations collectées pour cette initiative
peuvent être stockés sur des serveurs situés au Canada et peuvent être soumis aux lois canadiennes. Tous
l'identification téléchargée sur le portail sécurisé, sera supprimée par le personnel électoral dès
détermination de l’éligibilité des électeurs. `,
    helpInfo2: `Les questions concernant la collecte de ces informations personnelles peuvent être adressées au
Gestionnaire, élections et impression & Courrier, 71, rue Main Ouest, Hamilton ON L8P 4YS, 905-
546-2424 poste 2753.`,
    clickToBegin: "Cliquez pour commencer l'inscription",
    registerNow: 'Inscrivez-vous maintenant!',
    registrationOpen: 'Les inscriptions ouvriront dans',
    ifAnyQuestion:
      "Si vous avez des questions, vous pouvez nous contacter en utilisant l'adresse contactez-nous à nos numéros de téléphone ou à notre adresse e-mail.",
    contactPhone: 'Téléphone: 905-546-4408',
    registerForParticipation:
      "Les informations suivantes sont requises pour s'inscrire et participer à l'étude de volonté Igance.",
    singleName: "Un seul nom ? (J'ai un nom unique légalement enregistré)",
    contactInformation: 'Coordonnées',
    iDeclare: 'Je déclare que je suis un électeur éligible à cette élection',
    currentResAddress: 'Adresse résidentielle actuelle',
    searchAddress: 'Rechercher une adresse',
    typeAnAddress: 'Tapez une adresse...',
    addressNotListed: "Mon adresse n'est pas répertoriée, je l'ajouterai manuellement",
    uploadProof: "Télécharger une preuve d'identité",
    uploadSupportingDoc: 'Télécharger un document pris en charge',
    acceptDocs: 'Identité acceptée et documents pris en charge',
    delcareResidence:
      "Je déclare que je réside ou possède une propriété dans la ville de Hamilton (quartier 4) et que j'ai 18 ans.",
    declareAge:
      'Vous devez déclarer que vous résidez ou possédez une propriété et que vous avez 18 ans ou plus.',
    proofRequired: "Une preuve d'identité est requise",
    documentRequired: 'Un document pris en charge est requis',
    oneMoreStep: 'Un pas de plus !',
    weWillSendCode:
      "Nous vous enverrons un code de vérification, sélectionnez l'endroit où vous souhaitez que le code soit envoyé.",
    sendToEmail: 'Envoyer par email mon adresse (Email)',
    sendToPhone: 'Envoyer sur mon téléphone (SMS)',
    enterCode: 'Entrez le code !',
    weSentCode: 'Nous avons envoyé un code de vérification à 6 chiffres à votre',
    pleaseEnterCode: 'Veuillez saisir le code ci-dessous, il expirera au bout de cinq minutes.',
    didntRecieveCode: "Vous n'avez pas reçu le code ?",
    resend: 'Renvoyer',
    wrongEmail: 'Mauvaise adresse email ?',
    successfullyRegistered: 'Inscrit avec succès',
    successfullAccountMsg:
      "Votre compte a été créé avec succès ! Veuillez attendre l'approbation de l'administrateur des élections. Une fois approuvé, vous pouvez procéder au processus d’intégration.",
  },
}
