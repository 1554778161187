import {createContext, useContext, useEffect, useState} from 'react'
import {LanguageContext} from './LanguageContext'

export const VoterStylesContext = createContext()

export const VoterStylesProvider = ({children}) => {
  const [customBtnClass, setCustomBtnClass] = useState(null)
  const [customTxtClass, setCustomTxtClass] = useState(null)
  const [customLogoImg, setCustomLogoImg] = useState(null)
  const [customMobileLogoImg, setCustomMobileLogoImg] = useState(null)
  const [customAdminLogoImg, setCustomAdminLogoImg] = useState(null)
  const [customVoterLogoImg, setCustomVoterLogoImg] = useState(null)
  const [customBannerImg, setCustomBannerImg] = useState(null)
  const [voterStyleView, setVoterStyleView] = useState('pipsc')
  const [primaryColor, setPrimaryColor] = useState('#0065A4')

  const {currentLanguage} = useContext(LanguageContext)

  /** OPTIONS:
   * '' = default(Nova Scotia)
   * 'mexico' = INE
   * 'neuvote'  = neuvote
   * */

  // update voter styling
  useEffect(() => {
    setCustomBtnClass('voterStylesContext__btn')
    setCustomTxtClass('voterStylesContext__txt')
    setCustomLogoImg(`/media/logosbanners/hamilton.svg`)
    setCustomVoterLogoImg(`/media/logosbanners/hamilton.svg`)
    setCustomMobileLogoImg(`/media/logosbanners/hamilton.svg`)
    setCustomAdminLogoImg(`/media/logosbanners/hamilton.svg`)
    setCustomBannerImg('/media/logosbanners/hamiltonbanner.png')
  }, [voterStyleView, currentLanguage])

  const clearVoterStylesContext = () => {
    setCustomTxtClass(null)
    setCustomBtnClass(null)
  }

  return (
    <VoterStylesContext.Provider
      value={{
        customAdminLogoImg,
        primaryColor,
        voterStyleView,
        setVoterStyleView,
        customBtnClass,
        customTxtClass,
        customLogoImg,
        customMobileLogoImg,
        customBannerImg,
        customVoterLogoImg,
        setCustomTxtClass,
        setCustomBtnClass,
        setCustomLogoImg,
        setCustomVoterLogoImg,
        setCustomMobileLogoImg,
        clearVoterStylesContext,
      }}
    >
      {children}
    </VoterStylesContext.Provider>
  )
}

const PROVIDER_ERROR = 'useVoterStyles must be used within a VoterStylesProvider'

export const useVoterStyles = () => {
  const context = useContext(VoterStylesContext)
  if (!context) {
    throw new Error(PROVIDER_ERROR)
  }
  return context
}

export default VoterStylesContext
