import environment from '../../../../utils/environment'
import axios from 'axios'
// NOTE: IN PROGRESS: MAY CHANGE

const VoterRegistrationService = {
  checkEligible: async (data) => {
    try {
      await axios.post(
        `${environment.resolveApi().voter_reg_rest}/registration/checkeligible`,
        data
      )
      return {success: true}
    } catch (error) {
      return {error: error.response?.data?.error}
    }
  },
  sendSMSCode: async (phone, recaptchaToken) => {
    try {
      await axios.post(`${environment.resolveApi().voter_reg_rest}/registration/mfa/initiate/sms`, {
        phone,
        recaptchaToken,
      })
      return {success: true}
    } catch (error) {
      // console.log(error)
      return {error: error.response?.data?.error}
    }
  },
  sendEmailCode: async (email, recaptchaToken) => {
    try {
      await axios.post(
        `${environment.resolveApi().voter_reg_rest}/registration/mfa/initiate/email`,
        {email, recaptchaToken}
      )
      return {success: true}
    } catch (error) {
      // console.log(error)
      return {error: error.response?.data?.error}
    }
  },
  verifySMSCode: async (input) => {
    try {
      let result = await axios.post(
        `${environment.resolveApi().voter_reg_rest}/registration/mfa/verify/sms`,
        input
      )
      let data = result.data?.data
      return {
        success: true,
        data,
        userData: data,
        applicationCode: data?.applicationCode,
        status: data?.status,
      }
    } catch (error) {
      // console.log(error)
      return {error: error.response?.data?.error}
    }
  },
  verifyEmailCode: async (input) => {
    try {
      const result = await axios.post(
        `${environment.resolveApi().voter_reg_rest}/registration/mfa/verify/email`,
        input
      )
      let data = result.data?.data
      return {
        success: true,
        data,
        userData: data,
        applicationCode: data?.applicationCode,
        status: data?.status,
      }
    } catch (error) {
      // console.log(error)
      return {error: error.response?.data?.error}
    }
  },

  answerSurvey: async (payload) => {
    try {
      await axios.put(
        `${environment.resolveApi().voter_reg_rest}/registration/${payload.id}`,
        payload
      )
      return {success: true}
    } catch (error) {
      // console.log(error)
      return {error: error.response?.data?.error}
    }
  },
  verifyStatus: async ({confirmCode}) => {
    try {
      const result = await axios.get(
        `${environment.resolveApi().voter_reg_rest}/application-status/${confirmCode}`
      )

      return result
    } catch (error) {
      console.log(error)
    }
  },
}

export default VoterRegistrationService
