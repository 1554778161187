// @ts-nocheck
import axios from 'axios'
import environment from '../../../utils/environment'
import KeycloakService from '../../../KeycloakAdminService'
import Cookies from 'universal-cookie'
import {languageValueToKey} from '../../languages/languagekey'

const cookies = new Cookies()
const VERIFY_COOKIE = 'verifyTokenAdmin'

const axiosInstance = axios.create({
  baseURL: `${environment.resolveApi().voter_reg_rest}/admin`,
  withCredentials: true,
  headers: {},
})

// attach access token
axiosInstance.interceptors.request.use(
  async (config) => {
    const accessToken = KeycloakService.getToken()
    if (accessToken) {
      config.headers['Authorization'] = `Bearer ${accessToken}`
    }
    return config
  },
  (error) => {
    Promise.reject(error)
  }
)

// flattens the layer of nested introduced by axios
// the responses look like { data, error }, but axios nests the whole response under 'data'
axiosInstance.interceptors.response.use(
  (res) => {
    res = res.data
    if (res.accessToken) {
      cookies.set(VERIFY_COOKIE, res.accessToken)
    }
    if (res.link && res.link.includes('login-actions')) {
      window.location.replace(res.link)
      cookies.remove(VERIFY_COOKIE)
    }
    return res
  },
  async (err) => {
    const error = {
      ...err.response?.data?.error,
      ...err,
    }
    return Promise.reject(error)
  }
)

const AdminVoterService = {
  getUserInfoKeycloakToken: () => {
    try {
      const parsedToken = KeycloakService.getParsedToken()
      let user = {}
      if (parsedToken) {
        user = {
          username: parsedToken.preferred_username,
          email: parsedToken.email,
          phone: parsedToken.phone_number,
          phoneNumber: parsedToken.phone_number,
        }
      }
      return user
    } catch (error) {}
  },
  // TODO user not logged in keycloak
  // TODO update on login & if logged in whenever change
  //   updateUserLanguage: async (language) => {
  //     try {
  //       language = languageValueToKey[language]
  //       await axiosInstance.put('/account/language', {language: language})
  //     } catch (error) {}
  //   },

  syncEvents: async () => {
    try {
      let events = await axiosInstance.get('/events/v2/sync')
      return {events}
    } catch (error) {}
  },
  syncCalendarEvents: async () => {
    try {
      let events = await axiosInstance.get('/events/sync')
      return {events}
    } catch (error) {}
  },

  updateEvent: async (eventId, votingDistrict, votingChannel) => {
    try {
      let event = await axiosInstance.put(`/events/${eventId}`, {
        votingDistrict: votingDistrict,
        votingChannel: votingChannel,
      })
      return {event, success: true}
    } catch (error) {
      console.log(error)
    }
  },

  getVoterCount: async () => {
    try {
      let count = await axiosInstance.get('/count-users-db')
      return count
    } catch (error) {
      console.error(count)
    }
  },
  getEligibleVoterCount: async () => {
    try {
      let count = await axiosInstance.get('/count-eligible-users-db')
      return count
    } catch (error) {
      console.error(count)
    }
  },

  uploadVoterList: async (voterlist) => {
    try {
      let resp = await axiosInstance.post('/voters', voterlist)
      console.log(resp)
      return {success: true, ...resp}
    } catch (error) {
      if (error?.response?.data) {
        console.error(error.response.data)
        let errorDisplay = error.response.data
        if (errorDisplay.error?.code === 500) {
          return {
            error: {
              ...errorDisplay,
              message: 'Something went wrong, please contact support',
            },
          }
        }
        return errorDisplay
      } else {
        console.error(error)
        return {
          error: {
            code: 500,
            message: 'Something went wrong, please contact support',
          },
        }
      }
    }
  },

  uploadEligibleVoterList: async (voterlist) => {
    try {
      let resp = await axiosInstance.post('/eligiblevoters', voterlist)
      console.log(resp)
      return {success: true, ...resp}
    } catch (error) {
      if (error?.response?.data) {
        console.error(error.response.data)
        let errorDisplay = error.response.data
        if (errorDisplay.error?.code === 500) {
          return {
            error: {
              ...errorDisplay,
              message: 'Something went wrong, please contact support',
            },
          }
        }
        return errorDisplay
      } else {
        console.error(error)
        return {
          error: {
            code: 500,
            message: 'Something went wrong, please contact support',
          },
        }
      }
    }
  },

  uploadStrikeOffVoterList: async (voterlist) => {
    try {
      let resp = await axiosInstance.post('/eligiblevoters/strikeoff', voterlist)
      console.log(resp)
      return {success: true, ...resp}
    } catch (error) {
      if (error?.response?.data) {
        console.error(error.response.data)
        let errorDisplay = error.response.data
        if (errorDisplay.error?.code === 500) {
          return {
            error: {
              ...errorDisplay,
              message: 'Something went wrong, please contact support',
            },
          }
        }
        return errorDisplay
      } else {
        console.error(error)
        return {
          error: {
            code: 500,
            message: 'Something went wrong, please contact support',
          },
        }
      }
    }
  },
  removeIneligibleVoters: async (voterlist) => {
    try {
      let resp = await axiosInstance.post('/eligiblevoters/delete', voterlist)
      // console.log(resp)
      return {success: true, ...resp}
    } catch (error) {
      if (error?.response?.data) {
        console.error(error.response.data)
        let errorDisplay = error.response.data
        if (errorDisplay.error?.code === 500) {
          return {
            error: {
              ...errorDisplay,
              message: 'Something went wrong, please contact support',
            },
          }
        }
        return errorDisplay
      } else {
        console.error(error)
        return {
          error: {
            code: 500,
            message: 'Something went wrong, please contact support',
          },
        }
      }
    }
  },

  fetchVoters: async (
    districtFilter = 'all',
    channelFilter = 'all',
    limit = 1000,
    skip = 0,
    search = '',
    status = 'All',
    sort = ''
  ) => {
    try {
      let res = await axiosInstance.get(
        `/users?votingDistrict=${districtFilter}&votingChannel=${channelFilter}&limit=${limit}&skip=${skip}&search=${search}&status=${status}&sort=${sort}`
      )
      // console.log(res)
      return {success: true, users: res.users, numUsers: res.numUsers}
    } catch (error) {
      console.log(error)
    }
  },
  getVoterCsv: async (districtFilter = 'all', channelFilter = 'all') => {
    try {
      let result = await axiosInstance.get(
        `/users/csv?votingDistrict=${districtFilter}&votingChannel=${channelFilter}`,
        {
          responseType: 'blob',
        }
      )
      return {success: true, data: result}
    } catch (error) {
      console.log(error)
    }
  },
  fetchVotersFilters: async (
    filters = null,
    channelFilter = 'online',
    limit = 1000,
    skip = 0,
    search = ''
  ) => {
    try {
      let url = `/users?votingChannel=${channelFilter}&limit=${limit}&skip=${skip}&search=${search}`
      let keys = Object.keys(filters)
      for (var key of keys) {
        let filter = filters[key]
        if (filter) {
          let fitler_string = ''
          if (Array.isArray(filter)) {
            let values = filter.map((f) => f.accountId)
            fitler_string = `&${key}=${values.toString()}`
          } else {
            fitler_string = `&${key}=${filter.accountId}`
          }
          url += fitler_string
        }
      }
      // console.log(url)
      let res = await axiosInstance.get(url)
      // console.log(res)
      return {success: true, users: res.users, numUsers: res.numUsers}
    } catch (error) {
      console.log(error)
    }
  },
  getVoterCsvFilters: async (filters = null, channelFilter = 'online') => {
    try {
      let url = `/users/csv?votingChannel=${channelFilter}`
      let keys = Object.keys(filters)
      for (var key of keys) {
        let filter = filters[key]
        if (filter) {
          let fitler_string = ''
          if (Array.isArray(filter)) {
            let values = filter.map((f) => f.accountId)
            fitler_string = `&${key}=${values.toString()}`
          } else {
            fitler_string = `&${key}=${filter.accountId}`
          }
          url += fitler_string
        }
      }
      // console.log(url)
      let result = await axiosInstance.get(url, {
        responseType: 'blob',
      })
      return {success: true, data: result}
    } catch (error) {
      console.log(error)
    }
  },
  fetchEligibleVoters: async (districtFilter = 'all', limit = 1000, skip = 0, search = '') => {
    try {
      let res = await axiosInstance.get(
        `/eligiblevoters?votingDistrict=${districtFilter}&limit=${limit}&skip=${skip}&search=${search}`
      )
      // console.log(res)
      return {success: true, users: res.users, numUsers: res.numUsers}
    } catch (error) {
      console.log(error)
    }
  },
  fetchEligibleVotersCsv: async (districtFilter = 'all', limit = 1000, skip = 0, search = '') => {
    try {
      let result = await axiosInstance.get(`/eligiblevoters/csv`, {
        responseType: 'blob',
      })
      return {success: true, data: result}
    } catch (error) {
      console.log(error)
    }
  },
  getOnlineEventsList: async (district) => {
    try {
      let events = await axiosInstance.get(`/events/online?votingDistrict=${district}`)
      return events
    } catch (error) {
      console.error(error)
      // return {error}
    }
  },
  sendEmailsOnlineVoters: async (tiviId) => {
    try {
      if (tiviId) {
        await axiosInstance.post(`/events/${tiviId}/email/voters`)
      } else {
        await axiosInstance.post(`/email/voters`)
      }
      return {success: true}
    } catch (error) {
      console.error(error)
      // return {error}
    }
  },
  getVoteReceiptReport: async (tiviId, language) => {
    try {
      language = languageValueToKey[language]
      let data = await axiosInstance.get(`/votereceiptreport/${tiviId}?language=${language}`, {
        responseType: 'blob',
      })
      return {success: true, data}
    } catch (error) {
      console.error(error)
      // return {error}
    }
  },
  getAuthLogReport: async (formData, language) => {
    try {
      language = languageValueToKey[language]
      let url = `/authlogs/${formData.userType}?language=${language}`

      let startDate = ''
      let endDate = ''
      if (formData.startDate && formData.endDate) {
        startDate = formData.startDate.toISOString()
        endDate = formData.endDate.toISOString()
        url = `${url}&startDate=${startDate}&endDate=${endDate}`
      }
      let data = await axiosInstance.get(url, {
        responseType: 'blob',
      })
      return {success: true, data}
    } catch (error) {
      console.error(error)
      // return {error}
    }
  },
  updateUserLanguage: async (language) => {
    try {
      language = languageValueToKey[language]
      await axiosInstance.put('/account/language', {language: language})
    } catch (error) {}
  },
  // updateType is enum : ['edit, 'update']
  // voterId is user.voterId
  // tiviId is event.tiviId
  updateVoter: async ({voterId, email = '', phone = '', updateType}) => {
    try {
      await axiosInstance.post(`/users/${voterId}/update/${updateType}`, {
        email,
        phone,
      })
      return {success: true}
    } catch (error) {
      return {success: false}
    }
  },
  resetVoterPassword: async ({keycloakId}) => {
    try {
      await axiosInstance.post(`/users/${keycloakId}/resetpassword`)
      return {success: true}
    } catch (error) {
      return {success: false}
    }
  },
  getParentFilters: async (accountType, translateObj) => {
    try {
      // console.log('accountType', accountType)

      const response = await axiosInstance.get(`/pipsc/accounts?accountType=${accountType}`)
      let listOptions = response.map((r) => {
        return {
          ...r,
          label: translateObj(r.title?.en ? r.title : r.abbreviation),
          value: r.accountId,
        }
      })
      return listOptions
    } catch (error) {
      console.log(error)
    }
  },

  getChildFilters: async (parentId, translateObj) => {
    try {
      // console.log('parentId', parentId)
      const response = await axiosInstance.get(`/pipsc/accounts?parentId=${parentId}`)
      let listOptions = response.map((r) => {
        return {
          ...r,
          label: translateObj(r.title?.en ? r.title : r.abbreviation),
          value: r.accountId,
        }
      })
      return listOptions
    } catch (error) {
      console.log(error)
    }
  },
  approveVoter: async (id, electorId) => {
    try {
      await axiosInstance.put(`/voters/${id}/approve/${electorId}`)
      return {success: true}
    } catch (error) {
      return {error}
    }
  },
  declineVoter: async (id, reason) => {
    try {
      await axiosInstance.put(`/voters/${id}/decline`, {reason})
      return {success: true}
    } catch (error) {
      return {success: false}
    }
  },
  disableVoter: async (electorId) => {
    try {
      let response = await axiosInstance.put(`/eligiblevoters/${electorId}/disable`)

      return response
    } catch (error) {
      console.log(error)
    }
  },
  getFilterByGroup: async (accountType) => {
    try {
      const response = await axiosInstance.get(`/pipsc/accounts?accountType=${accountType}`)

      return response
    } catch (error) {
      console.log(error)
    }
  },

  getSubGroupOptions: async ({parentId}) => {
    try {
      const response = await axiosInstance.get(`/pipsc/accounts?parentId=${parentId}`)

      return response
    } catch (error) {
      console.log(error)
    }
  },
}

export default AdminVoterService
