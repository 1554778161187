import Modal from 'react-modal'
import {ModalStyling} from '../ModalStyling'

import {useContext, useEffect} from 'react'
import {useVoterStyles} from '../../../../../contexts/VoterStylesContext'
import {ModalFooter} from '../../../reusable/modals/modal_footer/ModalFooter'
import {toAbsoluteUrl} from '../../../../../helperfunctions/AssetHelpers'
import './FollowUpQuestionaireModal.scss'
import {VoterModalHeader} from '../../../../modals/voter_modal/VoterModalHeader'
import {ModalHeader} from '../../../reusable/modals/modal_header/ModalHeader'
import {ModalHeaderWithLogo} from '../../layout/ModalHeaderLogo'
import {CustomBtn} from '../../../../reusable/custom_btn/CustomBtn'
import TextField from '../../../../../modules/auth/reusable/TextField'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import TextAreaField from '../../../../../modules/auth/reusable/TextAreaField'
import {VoterRegistrationUserContext} from '../../../../../contexts/RegistrationUser'
import VoterRegistrationService from '../../../services/VoterRegistrationService'
import {useHistory} from 'react-router-dom'
import {useGoogleReCaptcha} from 'react-google-recaptcha-v3'
import {useTranslation} from '../../../../../hooks/useTranslation'
import ROUTE_PATHS from '../../../../../routing/RoutePaths'

export const FollowUpQuestionaireModal = ({modalOpen, setModalOpen, modalView, setModalView}) => {
  const {primaryColor} = useVoterStyles()
  const RegisterUser = useContext(VoterRegistrationUserContext)
  const history = useHistory()
  const {executeRecaptcha} = useGoogleReCaptcha()
  const {translate} = useTranslation()

  const initialValues = {
    question1: '',
    question2: '',
    question3A: '',
    question3B: '',
    question5: '',
  }

  const formik = useFormik({
    initialValues,
    validationSchema: Yup.object().shape({}),
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setTimeout(async () => {
        try {
          const token = await executeRecaptcha('register_participant3')
          const answers = {
            informedToMakeDecision: values.question1,
            reasonInformed: values.question2,
            infoNeededToDecide: values.question3A,
            preferredInfoDeliveryMethod: values.question3B,
            wantInterview: values.question5,
            id: RegisterUser.registrationUser.voterIdentifier,
            recaptchaToken: token,
          }

          // console.log('answers', answers)
          await VoterRegistrationService.answerSurvey(answers)
          RegisterUser.setRegistrationUser(null)
          setModalView('thank-you-final')
        } catch (error) {
          console.log(error)
        }
      }, 10)
    },
  })

  // thank you
  const thankYou = (
    <>
      <div className='followUpQuestionModal__thankYou'>
        <img
          className='followUpQuestionModal__thankYou__icon'
          alt='green circle check icon'
          src={toAbsoluteUrl('/media/design/icon_check_circle_green.svg')}
        />

        <span className='followUpQuestionModal__title'>{translate('Thank You')}</span>
        <span className='followUpQuestionModal__bodyTxt'>
          {translate(
            'Your registration has been submitted successfully. You will receive a confirmation email shortly.'
          )}
          <br />
        </span>
      </div>

      <div className='ModalFooter'>
        <CustomBtn
          view='ActionBtn'
          text={translate('Done')}
          height={50}
          onClick={() => {
            onClose()
          }}
        />
      </div>
    </>
  )
  const thankYouFinal = (
    <>
      <div className='followUpQuestionModal__thankYou'>
        {/* <img
          className='followUpQuestionModal__thankYou__icon'
          alt='green circle check icon'
          src={toAbsoluteUrl('/media/design/icon_check_circle_green.svg')}
        /> */}

        <span className='followUpQuestionModal__title'>Thank You</span>
        {formik.values.question5 === 'YES' ? (
          <span className='followUpQuestionModal__bodyTxt'>
            Thank you, for submitting your registration information, expect an email to complete the
            registration process soon and someone from the Engagement team will be in touch to set
            up a time for us to meet.
          </span>
        ) : (
          <span className='followUpQuestionModal__bodyTxt'>
            Thank you, for submitting your registration information, expect an email to complete the
            registration process soon.
          </span>
        )}
      </div>
      <div className='ModalFooter'>
        <CustomBtn
          view='ActionBtn'
          text={'Close and Exit'}
          height={50}
          onClick={() => {
            onClose()
          }}
        />
      </div>
    </>
  )

  const question1 = (
    <>
      <div className='followUpQuestionModal__thankYou'>
        <span className='followUpQuestionModal__bodyTxt'>
          If you were asked today to answer the question of whether or not you’d support a Deep
          Geological Repository near Ignace, do you have enough information to make a decision?
        </span>
      </div>

      <div className='ModalFooter'>
        <CustomBtn
          view='PrimaryBtn'
          text={'NO'}
          height={50}
          onClick={() => {
            formik.values.question1 = 'NO'
            setModalView('question-3')
          }}
          customStyling={{marginRight: 25}}
        />
        <CustomBtn
          view='ActionBtn'
          text={'YES'}
          height={50}
          onClick={() => {
            formik.values.question1 = 'YES'
            setModalView('question-2')
          }}
        />
      </div>
    </>
  )

  const question2 = (
    <>
      <div className='followUpQuestionModal__question2'>
        <span className='followUpQuestionModal__bodyTxt mb-5'>
          Please explain why you feel ready to log a choice?
        </span>

        <TextAreaField
          cols={100}
          rows={3}
          formik={formik}
          field='question2'
          placeholder='Type Here.'
          isRequired={true}
          noLabel={true}
          customClass={'TextField__voter'}
          customStyling={{borderRadius: 6, height: '100%'}}
        />
      </div>

      <div className='ModalFooter'>
        <CustomBtn
          view='PrimaryBtn'
          text={'Back'}
          height={50}
          onClick={() => {
            setModalView('question-1')
          }}
          customStyling={{marginRight: 25}}
        />
        <CustomBtn
          view='ActionBtn'
          text={'Next'}
          height={50}
          onClick={() => {
            setModalView('question-4')
          }}
        />
      </div>
    </>
  )

  const question3 = (
    <>
      <div className='followUpQuestionModal__question3'>
        <div className='followUpQuestionModal__inputTxtWrap mb-10'>
          <span className='followUpQuestionModal__bodyTxt mb-5'>
            What information do you need to help you make a decision?
          </span>
          <TextAreaField
            cols={100}
            rows={3}
            formik={formik}
            field='question3A'
            placeholder='Type Here.'
            isRequired={true}
            noLabel={true}
            customClass={'TextField__voter'}
            customStyling={{borderRadius: 6, height: '100%'}}
          />{' '}
        </div>
        <div className='followUpQuestionModal__inputTxtWrap'>
          <span className='followUpQuestionModal__bodyTxt mb-5'>
            Do you have a preference for the way you would receive that information?
          </span>
          <TextAreaField
            cols={100}
            rows={3}
            formik={formik}
            field='question3B'
            placeholder='printed mailed to me, conversations with experts, links online…'
            isRequired={true}
            noLabel={true}
            customClass={'TextField__voter'}
            customStyling={{borderRadius: 6, height: '100%'}}
          />{' '}
        </div>
      </div>

      <div className='ModalFooter'>
        <CustomBtn
          view='PrimaryBtn'
          text={'Back'}
          height={50}
          onClick={() => {
            setModalView('question-1')
          }}
          customStyling={{marginRight: 25}}
        />
        <CustomBtn
          view='ActionBtn'
          text={'Next'}
          height={50}
          onClick={() => {
            setModalView('question-4')
          }}
        />
      </div>
    </>
  )

  const question4 = (
    <>
      <div className='followUpQuestionModal__question4'>
        <img
          className='followUpQuestionModal__thankYou__icon'
          alt='circle alarm icon'
          src={toAbsoluteUrl('/media/design/icon_alarm_circle_grey.svg')}
        />

        <span className='followUpQuestionModal__bodyTxt mb-5'>
          The Engagement Team will be providing opportunities for you to participate in the
          Willingness Study for the potential sitting of a Deep Geological Repository near Ignace
          <strong> until April 30th 2024.</strong>
          {'\n'} <br />
          <br />
          <strong>On April 30th </strong>every registered participant will be asked to login to this
          system and answer if they are willing to continue to pursue locating a Deep Geological
          Repository of used nuclear fuel near Ignace. 
        </span>
      </div>

      <div className='ModalFooter'>
        <CustomBtn
          view='ActionBtn'
          text={'Next'}
          height={50}
          onClick={() => {
            setModalView('question-5')
          }}
        />
      </div>
    </>
  )

  const question5 = (
    <>
      <div className='followUpQuestionModal__question5'>
        <span className='followUpQuestionModal__bodyTxt'>
          Please indicate if you’d like to schedule a confidential interview with the Engagement
          team to discuss your expected choice that will be logged on April 30th.
        </span>
      </div>

      <div className='ModalFooter'>
        <CustomBtn
          view='PrimaryBtn'
          text={'NO'}
          height={50}
          onClick={() => {
            formik.values.question5 = 'NO'
            formik.submitForm()
          }}
          customStyling={{marginRight: 25}}
        />
        <CustomBtn
          view='ActionBtn'
          text={'YES'}
          height={50}
          onClick={() => {
            formik.values.question5 = 'YES'
            formik.submitForm()
          }}
        />
      </div>
    </>
  )

  const onClose = () => {
    setModalOpen(false)
    history.push(ROUTE_PATHS.LANDING)
    window.location.reload()
  }

  const renderView = () => {
    if (modalView == 'thank-you') {
      return thankYou
    }
    if (modalView == 'question-1') {
      return question1
    }
    if (modalView == 'question-2') {
      return question2
    }
    if (modalView == 'question-3') {
      return question3
    }
    if (modalView == 'question-4') {
      return question4
    }
    if (modalView == 'question-5') {
      return question5
    }
    if (modalView == 'thank-you-final') {
      return thankYouFinal
    }
  }

  useEffect(() => {
    Modal.setAppElement('#Voter')
  }, [])

  return (
    <Modal
      ariaHideApp={false}
      isOpen={modalOpen}
      onAfterOpen={() => {}}
      onRequestClose={() => {}}
      style={ModalStyling}
    >
      <div className='ModalWrapper' style={{flexGrow: 1}}>
        <ModalHeaderWithLogo />

        {/* BODY CONTENT */}
        {renderView()}
      </div>
    </Modal>
  )
}
