import VerificationInput from 'react-verification-input'
import CheckBox from '../../../../reusable/check_box/CheckBox'
import {CustomBtn} from '../../../../reusable/custom_btn/CustomBtn'
import {useContext, useState} from 'react'
import VoterRegistrationService from '../../../services/VoterRegistrationService'
import {VoterRegistrationUserContext} from '../../../../../contexts/RegistrationUser'
import {useGoogleReCaptcha} from 'react-google-recaptcha-v3'
import {Spinner} from 'react-bootstrap-v5'
import {useTranslation} from '../../../../../hooks/useTranslation'

export const FollowUpStep2 = ({setModalOpen, setModalView, selectedOption, setCurrentStep}) => {
  const [code, setCode] = useState(null)
  const [loading, setLoading] = useState(false)
  const [codeError, setCodeError] = useState('')
  const {translate} = useTranslation()
  let RegisterUser = useContext(VoterRegistrationUserContext)

  const {executeRecaptcha} = useGoogleReCaptcha()
  // add recaptcha
  const onSubmit = async () => {
    try {
      // console.log('code', code)

      if (!code || code.length < 6) {
        console.log('You must fill in all the inputs!')
        setCodeError(translate('You must enter the entire code!'))
      } else {
        setLoading(true)
        // const token = await executeRecaptcha('register_participant2')
        // RegisterUser.registrationUser.formData.set('recaptchaToken', token)

        RegisterUser.registrationUser.formData.set('verificationCode', code)
        let res
        if (selectedOption === 'SMS') {
          res = await VoterRegistrationService.verifySMSCode(RegisterUser.registrationUser.formData)
        } else {
          res = await VoterRegistrationService.verifyEmailCode(
            RegisterUser.registrationUser.formData
          )
        }
        if (res.success) {
          RegisterUser.setRegistrationUser(res.data)
          setModalView('thank-you')
          setModalOpen(true)
          setCodeError('')
        } else {
          setCodeError(translate('Invalid code'))
        }
        setLoading(false)
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div className='voterRegisterFollowUp__form'>
      <span className='verifyMethod__formHeaderTitle'>{translate('Enter code!')}</span>

      <span className='confirmCode__formHeaderTxt mt-10'>
        {translate('We sent you a 6 digit verification code. Please enter the code below.')}
      </span>

      <div className='voterRegisterFollowUp__form__options'>
        <div className='confirmCode__inputsWrap'>
          <VerificationInput
            autoFocus={true}
            inputProps={{autoComplete: 'one-time-code'}}
            value={code}
            onChange={setCode}
            validChars='0-9'
            classNames={{
              container: 'TextField__confirmCode__container',
              character: 'TextField__confirmCode',
              characterInactive: 'TextField__confirmCode__inactive',
              characterSelected: 'TextField__confirmCode__selected',
            }}
          />

          {codeError && (
            <div className='voterRegister__singleInputErr confirmCode__errorWrap'>
              <span className='TextField__voterErrTxt' role='alert'>
                {codeError}
              </span>
            </div>
          )}
          <div className='confirmCode__formFooter'>
            <span
              className='confirmCode__formFooterTxt'
              onClick={() => {
                setCurrentStep(1)
                setCodeError('')
              }}
            >
              {translate('Didn’t get it?')}
              <span className={'confirmCode__resendLink'}>{translate('Resend')}</span>
            </span>{' '}
          </div>
        </div>
      </div>
      {loading ? (
        <div style={{marginTop: 32}}>
          <Spinner animation={'border'} />
        </div>
      ) : (
        <CustomBtn
          view='ActionBtn'
          text={'Confirm'}
          height={50}
          customStyling={{width: '100%', marginTop: 32}}
          onClick={() => {
            onSubmit()
          }}
        />
      )}
    </div>
  )
}
