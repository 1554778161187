import {useEffect, useState} from 'react'
import {LineDivider} from '../../../../reusable/line_divider/LineDivider'
import './SuperAdmin__ParticipantDetails.scss'
import * as Yup from 'yup'

// import {ParticipantContactInfo as ContactInfo} from './components/contact_info/ParticipantContactInfo'
import {ParticipantContactInfo as ContactInfo} from './components/contact_info/ParticipantContactInfo'
import {ParticipantDuplication as DuplicationInfo} from './components/profile_duplication/ParticipantDuplication'

import {ParticipantLocationInfo as LocationInfo} from './components/location_info/ParticipantLocationInfo'
import {useHistory, useParams} from 'react-router-dom'
import AdminVoterAuthService from '../../../../../services/auth/AdminVoterAuthService'
import {Spinner} from 'react-bootstrap-v5'
import {CustomBtn} from '../../../../reusable/custom_btn/CustomBtn'
import {
  initialValues,
  step1Schema,
  step1SchemaWithSingleName,
  step2SchemaDefault,
  step2SchemaSecondary,
} from '../../../../voter/pages/VoterRegisterSchema2'
import {useFormik} from 'formik'
import {StatusBox} from '../../../../../resuable/status_box/StatusBox'
import AdminVoterService from '../../../../../services/admin/AdminVoterService'
import {BackBtn} from '../../../../reusable/back_btn/BackBtn'
import {SurveyResponses} from './views/SurveyResponses'
import {EditVoter} from './views/EditVoter'
import ScanResultBanner from '../../../../reusable/scan_result_banner/ScanResultBanner'
import {useTranslation} from '../../../../../hooks/useTranslation'
import ApproveVoterElectorId from './components/approveVoter/ApproveVoterElectorId'
import LanguageSpecificColon from '../../../../../components/language_specific_colon/LanguageSpecificColon'
import {ParticipantPhotoInfo} from './components/photo_info/ParticipantPhotoInfo'
import {EligibleMatch} from './components/profile_duplication/ProfileMatches'
import DeclineVoter from './components/declineVoter/DeclineVoter'
import {getDisplayAdress} from '../../../../../helperfunctions/AddressFormat'

interface RouteParams {
  voterId: string
}

export const SuperAdmin__ParticipantDetails = () => {
  const history = useHistory()
  // Explicitly define the type of parameters
  const {voterId} = useParams<RouteParams>()
  const [voter, setVoter] = useState(null)
  const [currentView, setCurrentView] = useState('default')
  const [singleName, setSingleName] = useState(false)
  const [formSchema, setFormSchema] = useState({})
  const [modalView, setModalView] = useState('')
  const [modalIsOpen, setIsOpen] = useState(false)
  const [voterTerms, setVoterTerms] = useState(false)
  const [termsError, setTermsError] = useState(false)
  const [onHasPropertyIgnace, setOnHasPropertyIgnace] = useState(false)
  const [loading, setLoading] = useState(false)
  const {translate} = useTranslation()

  const [isApproveModalOpen, setApproveModalOpen] = useState(false)
  const [isDeclineModalOpen, setDeclineModalOpen] = useState(false)

  const getVoterDetails = async () => {
    try {
      let voterDetails = await AdminVoterAuthService.getVoterDetails({voterId})

      setVoter(voterDetails)
    } catch (error) {
      console.log(error)
    }
  }

  function getMailingAddress(voter) {
    return voter.voterInfo.mailingAddress?.streetAddress
      ? voter.voterInfo.mailingAddress?.fullAddress
      : null
  }

  // const handleDecline = async (user) => {
  //   if (window.confirm(`${translate('Decline Voter')}`)) {
  //     setLoading(true)
  //     await AdminVoterService.declineVoter(user.voterInfo._id)
  //     await getVoterDetails()
  //     setLoading(false)
  //   }
  // }

  // const handleApprove = async (user, electorId) => {
  //   if (electorId) {
  //     console.log(electorId)
  //     setLoading(true)
  //     let result = await AdminVoterService.approveVoter(user.voterInfo._id, electorId)
  //     console.log(result)
  //     if (result.success) {
  //       await getVoterDetails()
  //     }
  //     setLoading(false)
  //     return result
  //   }
  // }

  useEffect(() => {
    getVoterDetails()
  }, [voterId, currentView])

  if (loading) {
    return <div className='SuperAdmin__ParticipantDetails'>Loading..</div>
  }

  if (!voter) {
    return <Spinner animation={'border'} />
  }

  if (currentView == 'survey-responses') {
    return <SurveyResponses voter={voter} setCurrentView={setCurrentView} />
  }

  return (
    <div className='SuperAdmin__ParticipantDetails' id='Voter'>
      <div className='participantDetails__header'>
        <BackBtn navigateTo={'/admin/electors-list'} customStyling={{marginBottom: 29}} />
      </div>

      {/* {voter.voterInfo?.status !== 'Pending' ? null : (
        <ScanResultBanner
          errorInfo={voter.voterInfo?.jumio}
          handleDecline={() => handleDecline(voter)}
        />
      )} */}
      {/* Body */}
      <div className='participantDetails__body'>
        {/* Header */}
        <div className='participantDetails__body__header'>
          <div className='participantDetails__body__header__nameContainer'>
            <span className='participantDetails__body__header__voterName'>
              {`${voter.voterInfo.firstName} ${voter.voterInfo.middleName} ${voter.voterInfo.lastName}`}
            </span>

            <div className='participantDetails__body__header__statusWrap'>
              {voter?.voteReceipts?.length ? (
                <StatusBox label='Voted' color='#08B055' />
              ) : voter.accessLinkStatus === 'invalid' ? (
                <StatusBox label='Onboarded' color='#6B7584' />
              ) : voter.voterInfo.status === 'Approved' ? (
                <StatusBox label='Approved' color='#08B055' />
              ) : null}

              {voter.voterInfo.status === 'Pending' && (
                <StatusBox label='Pending' color='#6B7584' />
              )}

              {voter.voterInfo.status === 'Declined' && (
                <StatusBox label='Declined' color='#FFA800' />
              )}
            </div>
            {/* <div> */}
            {voter.voterInfo.status === 'Approved' && (
              <span className='participantDetails__body__header__voterID'>
                {translate('Username')}
                <LanguageSpecificColon /> {voter.username}
              </span>
            )}
            {/* </div> */}
          </div>
        </div>

        <LineDivider />
        <ContactInfo dob={voter.voterInfo.dateOfBirth} phone={voter.phone} email={voter.email} />

        <LineDivider />

        <LocationInfo resAddress={getDisplayAdress(voter.voterInfo.address)} mailAddress={null} />

        <LineDivider />
        {voter.voterInfo.status === 'Pending' ? (
          <ParticipantPhotoInfo photos={voter.voterInfo.identification} />
        ) : null}

        {/* <LineDivider /> */}
        {/* 
        <DuplicationInfo
          profiles={[
            {name: 'John Doe Doe', address: '123 Ignace Road west, Ignace Ontario canada A9A 9A9'},
            {name: 'John Doe Doe', address: '123 Ignace Road west, Ignace Ontario canada A9A 9A9'},
          ]}
        /> */}

        {voter.duplicates && voter.duplicates.length ? (
          <>
            <LineDivider />
            <DuplicationInfo profiles={voter.duplicates} />
          </>
        ) : null}

        {voter.matches && voter.matches.length ? (
          <>
            <LineDivider />
            <EligibleMatch profiles={voter.matches} />
          </>
        ) : null}

        {/* Footer */}
        <div className='ModalFooter'>
          {voter.voterInfo.status === 'Pending' && (
            <CustomBtn
              view='PrimaryBtn'
              text={'Decline'}
              height={48}
              onClick={() => {
                setDeclineModalOpen(true)
              }}
            />
          )}
          {voter.voterInfo.status === 'Pending' && (
            <CustomBtn
              view='ActionBtn'
              text={'Approve'}
              height={48}
              onClick={() => {
                setApproveModalOpen(true)
              }}
            />
          )}
        </div>
      </div>
      <ApproveVoterElectorId
        isModalOpen={isApproveModalOpen}
        setModalOpen={setApproveModalOpen}
        user={voter}
        getVoterDetails={getVoterDetails}
        matches={voter.matches}
      />
      <DeclineVoter
        isModalOpen={isDeclineModalOpen}
        setModalOpen={setDeclineModalOpen}
        user={voter}
        getVoterDetails={getVoterDetails}
      />
    </div>
  )
}
