import {useEffect, useState} from 'react'
import useMobileView from '../../../../../../../hooks/UseMobileView'
import {useTranslation} from '../../../../../../../hooks/useTranslation'
import CustomModalWrapper from '../../../../../../reusable/custom_modal_wrapper/CustomModalWrapper'
import {ModalBtn} from '../../../../../../modals/voter_modal/ModalBtn'
import AdminVoterService from '../../../../../../../services/admin/AdminVoterService'
import SelectField from '../../../../../../../modules/auth/reusable/SelectField'

const ApproveVoterElectorId = ({isModalOpen, setModalOpen, user, getVoterDetails, matches}) => {
  const isMobileSize = useMobileView()
  const {translate} = useTranslation()
  const [electorId, setElectorId] = useState('')
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const [selectedOption, setSelectedOption] = useState(null)
  const [options, setOptions] = useState([
    {value: '', label: ''},
    {value: 'newregistrant', label: 'NEW REGISTRANT'},
  ])
  const ModalStyling = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      borderRadius: '12px',
      width: isMobileSize ? '100%' : 600,
      padding: 0,
      overflowY: 'scroll',
      maxHeight: 'calc(100% - 20px)',
    },
    overlay: {
      background: 'rgba(0, 0, 0, 0.5)',
      zIndex: 9999999999999999999,
      overflowY: 'scroll',
    },
  }
  console.log(selectedOption, 'slect')
  const handleApprove = async (user) => {
    setError('')
    setLoading(true)
    let result = await AdminVoterService.approveVoter(user.voterInfo._id, selectedOption)
    if (result.success) {
      await getVoterDetails()
      setModalOpen(false)
    } else if (result.error) {
      if (result.error.message === 'Voter already registered')
        setError('This voter has previously registered.')
      else if (result.error.message === 'Elector with this id already registered')
        setError('An elector with this elector ID has already registered')
      else
        setError(
          'Something went wrong, please ensure the elector has not already registered or voted.'
        )
    }
    setLoading(false)
    return result
  }
  useEffect(() => {
    setError('')
    let electorIds = matches?.map((e) => {
      return {value: e.electorId, label: e.electorId}
    })
    if (electorIds) {
      electorIds.unshift({value: '', label: ''})
      electorIds.push({value: 'newregistrant', label: 'NEW REGISTRANT'})
    }
    setOptions(electorIds)
  }, [])

  const renderView = () => {
    return (
      <div className='VotingReceiptM__receipt'>
        <label style={{fontSize: 15, marginBottom: 14, fontWeight: 'bold'}}>
          {translate('Select an Elector Id or select NEW REGISTRANT')}
        </label>

        <SelectField
          type='text'
          className={' TextField__voter'}
          field='electorId'
          isRequired={false}
          options={options}
          noPlaceholderValue={true}
          view='voter'
          customOnChange={(opt) => setSelectedOption(opt.target.value)}
        />

        {error && <span style={{color: 'red', margin: 5}}>{translate(error)}</span>}
        <div className='ModalFooter'>
          <ModalBtn view='CancelBtn' text='Close' onClick={() => setModalOpen(false)} />

          <button
            disabled={loading}
            className={'modalFooter__primaryBtn'}
            onClick={async (e) => {
              e.preventDefault()
              await handleApprove(user)
            }}
          >
            {translate('Approve')}
          </button>
        </div>
      </div>
    )
  }

  return (
    <CustomModalWrapper
      isOpen={isModalOpen}
      onClose={() => {
        setModalOpen(false)
      }}
      modalStyles={ModalStyling}
      headerLogo
    >
      {renderView()}
    </CustomModalWrapper>
  )
}

export default ApproveVoterElectorId
