import {useTranslation} from '../../hooks/useTranslation'
import './StatusBox.scss'

export const StatusBox = ({label, color, customStyling = {}}) => {
  const {translate} = useTranslation()
  return (
    <div className='StatusBox' style={{...customStyling, border: `1px solid ${color}`}}>
      <span className='statusBox__label' style={{color: color}}>
        {translate(label)}
      </span>
    </div>
  )
}
