import {useEffect, useState} from 'react'
import useMobileView from '../../../../../../../hooks/UseMobileView'
import {useTranslation} from '../../../../../../../hooks/useTranslation'
import CustomModalWrapper from '../../../../../../reusable/custom_modal_wrapper/CustomModalWrapper'
import {ModalBtn} from '../../../../../../modals/voter_modal/ModalBtn'
import AdminVoterService from '../../../../../../../services/admin/AdminVoterService'
import SelectField from '../../../../../../../modules/auth/reusable/SelectField'

const DeclineVoter = ({isModalOpen, setModalOpen, user, getVoterDetails}) => {
  const isMobileSize = useMobileView()
  const {translate} = useTranslation()
  const [electorId, setElectorId] = useState('')
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const [reason, setReason] = useState('')

  const ModalStyling = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      borderRadius: '12px',
      width: isMobileSize ? '100%' : 600,
      padding: 0,
      overflowY: 'scroll',
      maxHeight: 'calc(100% - 20px)',
    },
    overlay: {
      background: 'rgba(0, 0, 0, 0.5)',
      zIndex: 9999999999999999999,
      overflowY: 'scroll',
    },
  }

  const handleDecline = async (user) => {
    setLoading(true)
    setError('')
    let result = await AdminVoterService.declineVoter(user.voterInfo._id, reason)
    if (result.success) {
      await getVoterDetails()
      setModalOpen(false)
    } else {
      setError('Something went wrong. Please try again.')
    }
    setLoading(false)
    return result
  }

  useEffect(() => {
    setError('')
  }, [])

  const renderView = () => {
    return (
      <div className='VotingReceiptM__receipt'>
        <label style={{fontSize: 15, marginBottom: 14, fontWeight: 'bold'}}>
          {translate('Enter a reason for declining the registration.')}
        </label>
        <textarea
          value={reason}
          onChange={(e) => setReason(e.target.value)}
          placeholder='Uploaded documents too blurry, already registered, etc'
          cols={50}
          rows={6}
        />
        {error && <span style={{color: 'red', margin: 5}}>{translate(error)}</span>}
        <div className='ModalFooter'>
          <ModalBtn view='CancelBtn' text='Close' onClick={() => setModalOpen(false)} />

          <button
            disabled={loading}
            className={'modalFooter__primaryBtn'}
            onClick={async (e) => {
              e.preventDefault()
              await handleDecline(user)
            }}
          >
            {translate('Decline')}
          </button>
        </div>
      </div>
    )
  }

  return (
    <CustomModalWrapper
      isOpen={isModalOpen}
      onClose={() => {
        setModalOpen(false)
      }}
      modalStyles={ModalStyling}
      headerLogo
    >
      {renderView()}
    </CustomModalWrapper>
  )
}

export default DeclineVoter
