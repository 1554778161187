import {Redirect, Route, Switch} from 'react-router-dom'

import {useVoterStyles} from '../contexts/VoterStylesContext'

import {MEXICO__SuperAdmin__ElectorsList} from '../pages/super_admin/mexico/pages/electors_list/MEXICO__SuperAdmin__ElectorsList'
import {MEXICO__SuperAdmin__Users} from '../pages/super_admin/mexico/pages/admin_users/MEXICO__SuperAdmin__Users'
import {MEXICO__SuperAdmin__MyProfile} from '../pages/super_admin/mexico/pages/my_profile/MEXICO__SuperAdmin__MyProfile'

import {MEXICO__SuperAdmin__Reports} from '../pages/super_admin/mexico/pages/reports/MEXICO__SuperAdmin__Reports'
import {beforeUnload} from '../App'
import {useEffect} from 'react'
import {VoterListContextProvider} from '../contexts/VoterListContext'
import {MEXICO__SuperAdmin__HelpAndSupport} from '../pages/super_admin/help_and_support/MEXICO__SuperAdmin__HelpAndSupport'
import {MEXICO__AdminChangePassword} from '../pages/private/super_admin/change_password/MEXICO__AdminChangePassword'
import {MEXICO__SuperAdmin__Events} from '../pages/private/super_admin/events/MEXICO__SuperAdmin__Events'
import EligibleElectorsListTabsContainer from '../pages/super_admin/mexico/pages/electors_list/EligibleElectorsListTabsContainer'
import {SuperAdmin__ParticipantDetails} from '../pages/super_admin/mexico/pages/participant_details/SuperAdmin__ParticipantDetails'

export function SuperAdmin__DashboardRoutes() {
  const {voterStyleView} = useVoterStyles()

  useEffect(() => {
    window.addEventListener('beforeunload', beforeUnload)
  }, [])

  const MEXICO_ROUTES = (
    <Switch>
      <Route path='/admin/dashboard' component={MEXICO__SuperAdmin__Events} />
      <Route path='/admin/events' component={MEXICO__SuperAdmin__Events} />
      <Route path='/admin/electors-list' component={MEXICO__SuperAdmin__ElectorsList} />
      <Route path='/admin/admin-users' component={MEXICO__SuperAdmin__Users} />
      <Route path='/admin/my-profile' component={MEXICO__SuperAdmin__MyProfile} />
      <Route path='/admin/reports' component={MEXICO__SuperAdmin__Reports} />
      <Route path='/admin/change-password' component={MEXICO__AdminChangePassword} />
      <Route path='/admin/help-support' component={MEXICO__SuperAdmin__HelpAndSupport} />
      <Route render={() => <Redirect to='/admin/dashboard' />} />
    </Switch>
  )

  const NEUVOTE_ROUTES = (
    <VoterListContextProvider>
      <Switch>
        <Route path='/admin/dashboard' component={MEXICO__SuperAdmin__Events} />
        <Route path='/admin/events' component={MEXICO__SuperAdmin__Events} />
        <Route path='/admin/electors-list' component={MEXICO__SuperAdmin__ElectorsList} />
        <Route path='/admin/eligible-electors-list' component={EligibleElectorsListTabsContainer} />
        <Route path='/admin/admin-users' component={MEXICO__SuperAdmin__Users} />
        <Route path='/admin/my-profile' component={MEXICO__SuperAdmin__MyProfile} />
        <Route path='/admin/reports' component={MEXICO__SuperAdmin__Reports} />
        <Route path='/admin/change-password' component={MEXICO__AdminChangePassword} />
        <Route path='/admin/help-support' component={MEXICO__SuperAdmin__HelpAndSupport} />
        <Route path='/admin/voters/:voterId' component={SuperAdmin__ParticipantDetails} />
        {/* <Route render={() => <Redirect to='/admin/dashboard' />} /> */}
      </Switch>
    </VoterListContextProvider>
  )

  const NWT_ROUTES = (
    <>
      <Switch>
        <Route path='/admin/dashboard' component={MEXICO__SuperAdmin__Events} />
        <Route path='/admin/events' component={MEXICO__SuperAdmin__Events} />
        <Route path='/admin/electors-list' component={MEXICO__SuperAdmin__ElectorsList} />
        <Route path='/admin/admin-users' component={MEXICO__SuperAdmin__Users} />
        <Route path='/admin/my-profile' component={MEXICO__SuperAdmin__MyProfile} />
        <Route path='/admin/reports' component={MEXICO__SuperAdmin__Reports} />
        <Route path='/admin/change-password' component={MEXICO__AdminChangePassword} />
        <Route path='/admin/help-support' component={MEXICO__SuperAdmin__HelpAndSupport} />
        <Route render={() => <Redirect to='/admin/dashboard' />} />
      </Switch>
    </>
  )

  const renderRoutes = () => {
    if (voterStyleView == 'mexico') {
      return MEXICO_ROUTES
    } else if (voterStyleView == 'nwt') {
      return NWT_ROUTES
    } else {
      return NEUVOTE_ROUTES
    }
  }

  return <Switch>{renderRoutes()}</Switch>
}
