/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from 'react'
import {useHistory} from 'react-router-dom'
import {useFormik} from 'formik'
import {UserInfo} from '../../components/registration/UserInfo'
import {ContactInfo} from '../../components/registration/ContactInfo'
import '../VoterRegister.scss'
import '../../reusable/modals/VoterRegModals.scss'
import {initialValues, step1SchemaWithSingleName} from '../VoterRegisterSchema2'
import {useVoterStyles} from '../../../../contexts/VoterStylesContext'
import {CustomBtn} from '../../../reusable/custom_btn/CustomBtn'
import VoterRegisterFooter from '../../components/layout/VoterRegisterFooter'
import _ from 'lodash'

export function VoterRegisterStep1(props) {
  let {formik, contactsTermsErr, changeSchema, hasSingleName, setHasSingleName} = props
  const [loading, setLoading] = useState(false)

  const [singleName, setSingleName] = useState(false)
  // const [termsError, setTermsError] = useState(false)
  const history = useHistory()

  const {customBtnClass} = useVoterStyles()

  return (
    <>
      <UserInfo
        formik={formik}
        // hasSingleName={hasSingleName}
        onSingleName={(value) => {
          if (value) {
            setHasSingleName(true)
          } else {
            setHasSingleName(false)
          }
        }}
        changeSchema={changeSchema}
      />

      <ContactInfo formik={formik} view={'VoterRegister'} />

      <hr className='voterRegister__uploadDividerBottom'></hr>

      <div className='voterRegister__footerWrap'>
        {/* <CustomBtn view='PrimaryBtn' text='Clear Form' height={50} onClick={() => {}} /> */}

        {/* <CustomBtn view='PrimaryBtn' text={'Cancel'} height={50} onClick={() => {}} /> */}

        <CustomBtn
          view='ActionBtn'
          type='button'
          text={'Next'}
          height={50}
          onClick={() => {
            formik.submitForm()
          }}
          disabled={loading}
        />
      </div>
    </>
  )
}
