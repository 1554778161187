import {useContext} from 'react'
import {translate, translateEventName} from '../languages/translate'
import {LanguageContext} from '../contexts/LanguageContext'
import {translation as translationEN} from '../languages/registration/en'
import {translation as translationFR} from '../languages/registration/fr'

export const useTranslation = () => {
  const languageContext = useContext(LanguageContext)

  const translateString = (string) => {
    return translate(string, languageContext)
  }

  const translateObj = (obj) => {
    return translateEventName(obj, languageContext)
  }
  const translate2 = (str) => {
    if (languageContext.currentLanguage === 'French') {
      return translationFR['registration'][str]
    }
    return translationEN['registration'][str]
  }
  return {translate: translateString, translateObj, translate2}
}
