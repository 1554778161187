import React from 'react'
import SecurityIcon from '../icon/SheildCheckIcon'
import {useTranslation} from '../../../../hooks/useTranslation'

const VoterRegisterHeader = ({setModalView, setIsOpen}) => {
  const {translate} = useTranslation()
  return (
    <div>
      <div className='voterRegister__titleWrap'>
        <span className='voterRegister__title'>{translate('Elector Registration')}</span>

        {/* Security SVG IMG */}
        {/* <SecurityIcon setModalView={setModalView} setIsOpen={setIsOpen} /> */}
      </div>
      <span className='voterRegister__subtitle'>
        {translate('The following information is required to register to vote online.')}
      </span>
      <br />
      {/* <span className='voterRegister__disclaimer'>
        {translate(
          'Please note this registration form is set up for demonstrative purposes only. Idenity document verification is not required for this demo form.'
        )}
      </span> */}
    </div>
  )
}

export default VoterRegisterHeader
