/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useCallback, useRef, useEffect} from 'react'
// import {IProfileDetails, profileDetailsInitValues as initialValues} from '../../../modules/profile/components/settings/SettingsModel'
import {useHistory} from 'react-router-dom'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import '../../pages/VoterRegister.scss'
import {v4 as uuidv4} from 'uuid'
import {AddImageModal} from '../../../../pages/modals/AddImageModal'
import {useVoterStyles} from '../../../../contexts/VoterStylesContext'
import './UploadPhotoID.scss'
import {toAbsoluteUrl} from '../../../../../_metronic/helpers'
import {useTranslation} from '../../../../hooks/useTranslation'

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '12px',
  },
  overlay: {
    background: 'rgba(0, 0, 0, 0.5)',
  },
}

export function UploadPhotoID(props) {
  const {formik, setModalView, setIsOpen, setPhotoID, view, formikValueName, customStyling} = props
  const uploadRef = useRef(null)
  const [isUploading, setIsUploading] = useState(false)

  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [vidModalIsOpen, setVidModalOpen] = useState(false)
  // const [imgContents, setImgContents] = useState(null)
  const [photoURL, setPhotoURL] = useState(null)
  const {translate} = useTranslation()

  const {customBtnClass, customTxtClass} = useVoterStyles()

  function afterOpenModal() {}

  function onAfterCompleteModal() {}

  function closeModal() {
    setModalIsOpen(false)
  }

  return (
    <div id='CreatePostModal' style={customStyling}>
      <hr className='voterRegister__uploadDividerTop'></hr>

      <AddImageModal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel='Example Modal'
        setPostURL={(imgURL, path) => {
          console.log('imgURL', imgURL)
          setPhotoURL(imgURL)
          // setPostURL({type: 'image', url: imgURL, path: path})
        }}
        setFileData={(image) => {
          formik.values[formikValueName] = image

          if (formik.errors[formikValueName]) {
            formik.errors[formikValueName] = ''

            formik.setFieldError(formikValueName, '')
          }

          // TODO: will remove this late
          // setPhotoID(image)
          // console.log(image)
        }}
        modalView='photoID'
      />

      <div className='voterRegister__uploadIDWrap'>
        <span className='voterRegister__uploadIDLabel required'>
          {view == 'AdminEdit'
            ? translate('Photo ID')
            : translate('Upload proof of identity and residence')}
        </span>

        {photoURL && <img className='voterRegister__uploadedPhoto' src={photoURL} />}
        <div className='voterRegister__uploadBtnWrap'>
          <button
            type='button'
            className={
              customBtnClass
                ? 'voterRegister__uploadBtn' + ' ' + customBtnClass
                : 'voterRegister__uploadBtn'
            }
            onClick={() => {
              setModalIsOpen(true)
            }}
          >
            {!photoURL ? translate('Upload') : translate('Change')}
          </button>
          {view !== 'AdminEdit' && (
            <a
              href='https://www.canlii.org/en/on/laws/regu/o-reg-304-13/latest/o-reg-304-13.html'
              target='_blank'
              className={
                customTxtClass
                  ? 'voterRegister__uploadDisclaimerTxt' + ' ' + customTxtClass
                  : customTxtClass
              }
              rel='noreferrer'
            >
              {translate('Accepted documents')}
            </a>
          )}
        </div>
      </div>
    </div>
  )
}
