/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useEffect, useContext, useCallback, useRef} from 'react'

import {toAbsoluteUrl} from '../../helperfunctions/AssetHelpers'

import Modal from 'react-modal'

import {ModalHeader} from './ModalHeader'
import {useVoterStyles} from '../../contexts/VoterStylesContext'

import './AddImageModal.scss'
import './CivikModal.scss'
import './AddImageModal.scss'

import {useTranslation} from '../../hooks/useTranslation'
import {addPeriodToText} from '../common/utility_functions/TextUtility'

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '12px',
    padding: '0px',
    width: '94%',
    maxWidth: 520,
  },
  overlay: {
    background: 'rgba(0, 0, 0, 0.5)',
    zIndex: '9999999999',
  },
}

export function AddImageModal(props) {
  const {modalView} = props
  const {translate} = useTranslation()

  const [loading, setLoading] = useState(false)
  const [isUploading, setIsUploading] = useState(false)

  const [files, setFiles] = useState([])
  const [picUrl, setPictureUrl] = useState('')
  const [picPath, setPicturePath] = useState('')
  const [localFileData, setLocalFileData] = useState(null)

  const {customBtnClass} = useVoterStyles()
  // const onEditStaff = useCallback(() => MicroModal.show('modal-1'), [])

  const onFileUpload = async (e) => {
    try {
      setIsUploading(true)
      e.persist()

      const file = e.target.files[0]
      setLocalFileData(file)
      console.log('file', file)

      //  If the file size (calculated in byte form) is than 1MB
      // if (file.size > 1.0e6) {
      //   console.log('IMAGE SIZE MUST BE LESS THAN 1MB')
      // }
      if (file.type !== 'image/png' && file.type !== 'image/jpeg') {
        return console.log('IMAGE TYPE MUST BE PNG OR JPG')
      }

      console.log('uploading image now!....')

      // let usr_civik_id = PROFILE_CONTEXT.profile._id

      console.log('starting UPLOAD ========')
      //Firebase path for post media:
      //posts/{{random uuid for post}}/media/{{a different random uuid for each media item}}

      // if, modal is for photoID
      if (modalView == 'photoID') {
        setPictureUrl(URL.createObjectURL(file))
      }

      console.log('done')

      setIsUploading(false)
    } catch (error) {
      console.error(error)
    }
  }

  const resetModal = () => {
    try {
      setPictureUrl(null)
      setPicturePath(null)
    } catch (error) {
      console.log(error)
    }
  }

  const uploadRef = useRef(null)

  const onImageClick = useCallback(() => {
    uploadRef.current.click()
  }, [uploadRef])

  useEffect(() => {
    resetModal()
  }, [props.onRequestClose])

  useEffect(() => {
    // Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
    Modal.setAppElement('#CreatePostModal')
  }, [])

  return (
    <Modal
      ariaHideApp={false}
      isOpen={props.isOpen}
      onAfterOpen={props.onAfterOpen}
      onRequestClose={props.onRequestClose}
      style={customStyles}
      contentLabel='Example Modal'
    >
      <form className='AddImageModal'>
        {/* HEADER */}
        <ModalHeader title={translate('Add Image')} onRequestClose={props.onRequestClose} />

        {/* BODY */}
        <div>
          {isUploading && (
            <div className='addImageModal_uploadingLoading'>
              <h1>{translate('Uploading...')}</h1>
            </div>
          )}

          {picUrl && !isUploading && (
            <div className='addImageModal__uploadContainer' onClick={onImageClick}>
              <img className='addImageModal__uploadedImg' src={picUrl} />
            </div>
          )}

          {!picUrl && !isUploading && (
            <div className='addImageModal__uploadContainer' onClick={onImageClick}>
              <img src={toAbsoluteUrl('/media/design/file_upload_icon.svg')} />
              <h4 className='addImageModal__uploadTxt'>{translate('Image Uploader')}</h4>
              <span className='addImageModal__uploadSubTxt'>
                {addPeriodToText(translate('Click to add files 5MB Max (jpg, png)'))}
              </span>
            </div>
          )}

          <input
            type='file'
            onChange={onFileUpload}
            ref={uploadRef}
            style={{display: 'none'}}
            accept='image/png, image/jpeg'
          />
        </div>

        {/* KEEPING THIS HERE FOR MULTIPLE FILE SOLUTION .. */}
        {/* <div className='doc-input-container'>
          <FilePond
            ref={uploadRef}
            files={files}
            allowMultiple={true}
            onupdatefiles={(fileItems) => {
              console.log('fileItems', fileItems)
              setFiles(fileItems.map((fileItem) => fileItem.file))
            }}
            labelIdle='<span>Drag & Drop files here 5MB Max (jpg, gif, png)</span>'
          />
        </div> */}

        {/* FOOTER */}
        <div className='civikModal__footer'>
          <button
            type='button'
            id='kt_sign_up_submit'
            className='btn btn-lg btn-active-light-primary btn btn-light  '
            style={{maxWidth: 110}}
            onClick={props.onRequestClose}
          >
            <span className='indicator-label fw-bolder'> {translate('Back')}</span>
          </button>
          <button
            type='button'
            id='kt_sign_up_submit'
            className={
              customBtnClass
                ? 'modalFooter__primaryBtn' + ' ' + customBtnClass
                : 'modalFooter__primaryBtn'
            }
            style={{marginLeft: 20, maxWidth: '110'}}
            onClick={() => {
              props.setFileData(localFileData)
              props.setPostURL(picUrl, picPath)
              props.onRequestClose()
            }}
          >
            {!isUploading && <span className='indicator-label fw-bolder'>{translate('Add')}</span>}
            {isUploading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                {translate('Please wait...')}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </form>
    </Modal>
  )
}
